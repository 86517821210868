import { ArrowBack, Groups, LocalDining, PinDrop, VpnKey } from "@mui/icons-material";
import { Button, Card, CardActionArea, CardContent, CardMedia, CircularProgress, Container, Input, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../components/Title";
import { API_URL } from "../config";
import { _t } from "../utils/i18n";
import xhr from "../xhr";
import ChallengeCard from "./ChallengeCard";

const Search = () => {
  const navigate = useNavigate();

  const [mode, setMode] = useState(null);
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [codeNotFound, setCodeNotFound] = useState(false);
  const [challenges, setChallenges] = useState([]);

  useEffect(() => {
    setCodeNotFound(false);
    if (code.length !== 6) return;

    const fetchChallenge = async () => {
      const data = await xhr(API_URL.clone().segment('challenges').segment('search').addSearch('code', code).toString(), {});
      setLoading(false);

      if (data.error) {
        setCodeNotFound(true);
        return;
      }

      setChallenges([data.challenge]);
    };

    setLoading(true);
    fetchChallenge();

  }, [code]);

  useEffect(() => {
    if (mode !== 'clubs') return;

    const fetchChallenges = async () => {
      const data = await xhr(API_URL.clone().segment('challenges').addSearch('mine', true).toString(), {});
      setLoading(false);

      if (data.error) {
        console.log(data.error);
        return;
      }

      setChallenges(data.challenges);
    };

    setLoading(true);
    fetchChallenges();
  }, [mode]);

  return (
    <Box display="flex">

      <Container maxWidth="md">

        <Title
          returnAction={() => {
            if (mode === null) {
              navigate(-1);
              return;
            }

            setMode(null);
            setChallenges([]);
          }}
        >
          {_t("Find a challenge")}
        </Title>

        {mode === 'code' && (
          <Box mt={4} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <Typography>{_t("I know the challenge code")}</Typography>
            <Input
              value={code}
              onChange={(e) => setCode(e.target.value)}
              maxLength={6}
              placeholder="XXXXXX"
            />
            <Box mt={2}>

              {codeNotFound ? (
                <Button variant="contained" color="error" disabled>{_t("Code not found")}</Button>
              ) : (
                <Button variant="contained" color="primary" disabled={!loading}>{loading ? <CircularProgress sx={{ color: "white" }}size="20px" /> : _t("Find")}</Button>
              )}
            </Box>
          </Box>
        )}

        {mode === 'clubs' && (
          <Box mt={4} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <Typography>{_t("List of my clubs' challenges")}</Typography>

            {loading && (
              <Box mt={2}>
                {_t("Loading...")}
              </Box>
            )}

            {!loading && challenges.length === 0 && (
              <Box mt={2}>
                {_t("There is no challenge associated to your clubs")}
              </Box>
            )}

          </Box>
        )}

        {mode === null && (
          <Box mt={2} display="flex" alignItems="center" justifyContent="center" flexDirection="column">

            <Card sx={{ maxWidth: 345, marginBottom: 2 }} onClick={() => setMode('code')}>
              <CardActionArea>
                <CardMedia
                  sx={{
                    height: '60px',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)"
                  }}
                >
                  <VpnKey />
                </CardMedia>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {_t("Code")}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {_t("If you know a challenge code, you can join it by entering it here.")}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>

            <Card sx={{ maxWidth: 345, marginBottom: 2 }} onClick={() => setMode('clubs')}>
              <CardActionArea>
                <CardMedia
                  sx={{
                    height: '60px',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "linear-gradient(45deg, #6bd5fe 30%, #1fbffd 90%)"
                  }}
                >
                  <Groups />
                </CardMedia>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {_t("Club")}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {_t("If you are part of clubs on Strava, you can find the associated challenges.")}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>

            <Card sx={{ maxWidth: 345, marginBottom: 2 }} onClick={() => {}}>
              <CardActionArea>
                <CardMedia
                  sx={{
                    height: '60px',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "linear-gradient(45deg, #fdcd1f 30%, #fede6b 90%)"
                  }}
                >
                  <PinDrop />
                </CardMedia>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {_t("Around me")}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {_t("Find a challenge nearby from your position (coming soon)")}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>

          </Box>
        )}

        {challenges.length > 0 && (
          <Box mt={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            {challenges.map((challenge, i) => <ChallengeCard key={i} {...challenge} />)}
          </Box>
        )}

        </Container>
    </Box>
  )
};

export default Search;
