import { Button, Modal, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { getStorage } from "../utils";
import { alert } from "../utils/emitter";
import { _t } from "../utils/i18n";
import xhr from "../xhr";
import { API_URL } from "../config";

const Feedback = () => {
  const session = getStorage("session");
  const [show, setShow] = useState(false);
  const [content, setContent] = useState("");

  const onSend = async () => {
    const data = await xhr(API_URL.clone().segment("feedback"), {
      method: "POST",
      body: JSON.stringify({ feedback: content }),
    });

    console.log('ici', data);

    if (!data.message)
      return alert(data.error);

    alert(_t("Feedback sent!"), "success", 5000);
    setContent("");
    setShow(false);
  };

  if (!session) return null;

  if (!show) {
    return (
      <Box
        onClick={() => setShow(true)}
        sx={{
          position: "fixed",
          right: {
            xs: "-58px", md: "-90px",
          },
          top: "50%",
          zIndex: 1000,
          background: "#000",
          color: "#fff",
          textTransform: "uppercase",
          fontSize: {
            xs: "8px", md: "12px",
          },
          padding: {
            xs: "5px 10px", md: "10px 20px",
          },
          fontWeight: "bold",
          transformOrigin: "0 0",
          transform: "rotate(90deg) translateY(-50%)",
      }}>
        {_t("Feedback")}
      </Box>
    );
  }

  return (
    <Modal
      open={true}
      onClose={() => setShow(false)}
    >
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "#fff",
          padding: "20px",
          width: "80vw",
          maxWidth: "600px",
          borderRadius: "5px",
          zIndex: 1000,
        }}
      >
        <Box>
          <Typography variant="h5">{_t("Leave a feedback")}</Typography>
        </Box>
        <Box>
        <TextField
          sx={{
            width: "100%",
            minHeight: "100px",
            margin: "20px 0",
          }}
          value={content}
          placeholder={_t("Jolkia is in its infancy, and any comments, feedback, feature requests are greatly appreciated to improve it.\n\nThank you!")}
          multiline
          rows={6}
          onChange={(e) => setContent(e.target.value)}
        />
        <Button variant="contained" fullWidth onClick={onSend}>{_t("Send")}</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default Feedback;
