import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../config";
import { getStorage, setStorage } from "../utils";
import { alert } from "../utils/emitter";
import xhr from "../xhr";
import dayjs from "dayjs";

const Admin = () => {
  const user = getStorage('user');
  const session = getStorage('session');

  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (!user) return;

    const fetchUsers = async () => {
      const data = await xhr(API_URL.clone().segment('root').segment('users').toString(), {});

      if (data.error) {
        alert(data.error, 'error', 5000);
        return;
      }

      setUsers(data.users.sort((a, b) => dayjs(b.created_on).unix() - dayjs(a.created_on).unix()));
    };

    fetchUsers();
  }, []);

  const onImpersonnate = async (id) => {
    const data = await xhr(API_URL.clone()
      .segment('root')
      .segment('users')
      .segment(id)
      .segment('impersonnate')
      .toString(), { method: 'POST' });

      if (data.error) {
        alert(data.error, 'error', 5000);
        return;
      }

      setStorage('root_user', user);
      setStorage('root_session', session);

      setStorage('user', data.user);
      setStorage('session', data.session);

      alert('Vous êtes maintenant connecté en tant que ' + data.user.username, 'success', 5000);
      navigate('/');
  };

  if (!user) {
    navigate('/connect');
    return;
  }

  return (
    <Box>
      <Typography variant="h4">Administration</Typography>
      <Box>
        <Typography variant="h6">Utilisateurs</Typography>
        <Box>
          {users.map((user) => (
            <Box key={user.id}>
              <Typography variant="div"><strong>{user.username}</strong></Typography>

              &nbsp;•&nbsp;
              <Typography variant="div">{dayjs(user.created_on).format('DD/MM/YYYY')}</Typography>

              {user.email && (
                <>
                  &nbsp;•&nbsp;
                  <Typography sx={{ fontSize: '10px' }} variant="div">({user.email})</Typography>
                </>
              )}

              <Button onClick={() => onImpersonnate(user.id)}>Impersonate</Button>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Admin;
