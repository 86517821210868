export const getStorage = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (e) {
    return null;
  }
}

export const setStorage = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    return false;
  }

  return true;
}

export const removeStorage = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    return false;
  }

  return true;
}
