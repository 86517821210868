import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import humanizeDuration from '../utils/humanizeDuration';

import { API_URL } from '../config';

import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { _t, _n } from '../utils/i18n';

const sortEfforts = (efforts) => {
 const sortedEfforts = {};

  efforts.forEach((effort) => {
    if (!sortedEfforts[effort.segment_id])
      sortedEfforts[effort.segment_id] = [];

    sortedEfforts[effort.segment_id].push(effort);
  });

  Object.entries(sortedEfforts).forEach(([key, value]) => {
    sortedEfforts[key] = value.sort((a, b) => a.elapsed_time - b.elapsed_time);
  });

  return sortedEfforts;
};

const GP_POINTS = [25, 18, 15, 12, 10, 8, 6, 4, 2, 1];

const Segments = ({ challenge }) => {
  const [error, setError ] = useState(null);
  const [segments, setSegments] = useState(null);
  const [efforts, setEfforts] = useState([]);
  const once = useRef(false);
  const [focusedSegment, setFocusedSegment] = useState(null);

  useEffect(() => {
    if (once.current) return;

    once.current = true;
    const fetchData = async () => {
      const url = API_URL
        .clone()
        .segment('challenges')
        .segment(`${challenge.id}`)
        .segment('segments')
        .toString();

      try {
        const response = await fetch(url, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json'
          },
        });
        const data = await response.json();

        if (data.error) {
          setError(data.error);
          return;
        }

        setEfforts(sortEfforts(data.efforts));
        setSegments(data.segments);
      } catch (e) {
        setError(_t("Error while fetching segments"));
      }
    };

    fetchData();
  }, []);

  if (error) {
    return (
      <Box>
        <Box>{_t("An error occured while trying to retrieve segments:")} {error}</Box>
      </Box>
    );
  }

  if (segments === null && error === null) {
    return (
      <Box>{_t("Loading...")}</Box>
    );
  }

  return (
    <Box>
      {focusedSegment && (
        <Modal
          open={true}
          onClose={() => setFocusedSegment(null)}
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '300px',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}>
            <h2 id="modal-modal-title">{_t("Segment's efforts")}</h2>
            <Box id="modal-modal-description">
              {!efforts[focusedSegment] && (
                <Box>{_t("No efforts yet")}</Box>
              )}

              {efforts[focusedSegment] && efforts[focusedSegment].map((effort, i) => (
                <Box display="flex" key={i}>
                  <Box mr={2}>+{i >= 10 ? 0 : GP_POINTS[i]}pts</Box>
                  <Box>
                    <Box>{effort.first_name} {effort.last_name}</Box>
                    <Box><strong>{humanizeDuration(effort.elapsed_time)}</strong> • {_n("{count} attempt", "{count} attempts", effort.attempts, { count: effort.attempts })}</Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Modal>
      )}


      {segments.map((segment, i) => {
        return <>
          <Box display="flex" mb={2} key={i}>
            <Box display="flex" alignItems="flex-start" flex="1 auto">
              <Box display="flex">
                <Box sx={{ textAlign: "left", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                  <span><a target="_blank" href={`https://www.strava.com/segments/${segment.strava_id}`}><strong>{segment.data.name}</strong></a> • {segment.data.city}</span>
                  <span>{_t("Distance:")} {segment.data.distance < 1000 ? `${segment.data.distance}m` : `${Math.floor(segment.data.distance/1000)}km`}</span>
                  <span>{_t("Average grade:")} {segment.data.average_grade}%  {segment.data.climb_category > 0 && <span>(Cat {segment.data.climb_category})</span> }</span>
                </Box>
              </Box>
            </Box>
            <Box flex="none" display="flex" alignItems="stretch">
              <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" onClick={() => setFocusedSegment(segment.strava_id)}>
                <VisibilityIcon />
                <Box sx={{ fontSize: "10px" }}>({efforts[segment.strava_id] ? efforts[segment.strava_id].length : 0 })</Box>
              </Box>
            </Box>
          </Box>
          <Divider variant="inset" component="div" sx={{ margin: "5px 0px" }} />
        </>
      })}
    </Box>
  );
}

export default Segments;
