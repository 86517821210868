import { Clear } from "@mui/icons-material";
import { Button, IconButton, List, ListItem, Switch, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import Title from "../components/Title";
import { API_URL } from "../config";
import { getStorage } from "../utils";
import { alert } from "../utils/emitter";
import { _n, _t } from "../utils/i18n";
import xhr from "../xhr";

const MAX_SEGMENTS = 25;

const ManageSegments = () => {
  const user = getStorage("user");
  const navigate = useNavigate();
  const params = useParams();

  const [challenge, setChallenge] = useState(null);
  const [segments, setSegments] = useState(null);
  const [selected, setSelected] = useState([]);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    const fetchChallenge = async () => {
      try {
        const data = await xhr(API_URL.clone().segment('challenges').segment(params.id).toString());

        if (data.error) {
          alert(data.error, "error", 5000);
          return;
        }

        setChallenge(data.challenge);
        setSelected(data.challenge.segments);
      } catch (e) {
        alert(_t("Error while fetching challenge"), "error", 5000);
      }
    };

    const fetchSegments = async () => {
      try {
        const data = await xhr(API_URL.clone().segment('segments').segment('starred').toString());

        if (data.error) {
          alert(data.error, "error", 5000);
          return;
        }

        setSegments(data.segments);
      } catch (e) {
        alert(_t("Error while fetching segments"), "error", 5000);
      }
    };

    fetchSegments();
    fetchChallenge();
  }, []);

  if (!challenge || !segments)
    return <Loader />;

  if (!user) {
    alert(_t("You must be logged in to access this page"), "error")
    navigate("/connect");
    return null;
  }

  if (![challenge.user_id, challenge.admin_id].includes(user.id)) {
    alert(_t("You don't have the right to access this page"), "error")
    navigate("/");
    return null;
  }

  const handleToggle = async (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((s) => s !== id));
    } else {
      if (selected.length >= MAX_SEGMENTS) {
        alert(_t("A challenge can't have more than {count} segments", { count: MAX_SEGMENTS }), "error", 5000);
        return;
      }

      setSelected([...selected, id]);
    }
  };

  const onSave = async () => {
    try {
      const data = await xhr(API_URL.clone().segment('challenges').segment(params.id).segment('segments').toString(), {
        method: "POST",
        body: JSON.stringify({ segments: selected }),
      });

      if (data.error) {
        alert(data.error, "error", 5000);
        return;
      }

      alert(_t("Segments updated"), "success", 5000);
      navigate(`/challenge/${challenge.id}`);
    } catch (e) {
      alert(_t("Error while updating segments"), "error", 5000);
    }
  };

  const filteredSegments = filter.length ? segments.filter((segment) => segment.name.toLowerCase().includes(filter)) : segments;

  return (
    <Box>
      <Title
        returnAction={() => navigate(`/challenge/${challenge.id}`)}
      >
        {_t("Challenge segments")}
      </Title>
      <Box display="flex" flexDirection="column" sx={{ height: "85vh" }}>
        <Box display="flex" m={2}>
          <Typography>{_t("Select here the segments for the challenge from all your Strava starred segments.")}</Typography>
        </Box>

        <TextField
          sx={{ margin: "10px" }}
          label={_t("Search")}
          variant="outlined"
          value={filter}
          onChange={(e) => setFilter(e.target.value.toLowerCase())}
          InputProps={{
            endAdornment: (
              <IconButton
                sx={{ visibility: filter.length ? "visible" : "hidden" }}
                onClick={() => setFilter("")}
              >
                <Clear />
              </IconButton>
            ),
          }}
        />

        <Typography sx={{ fontSize: "12px", textDecoration: "bold", textAlign: "right", marginRight: "15px" }} variant="span">{_n('{count} selected', '{count} selected', selected.length, { count: selected.length })}</Typography>
        <Box display="flex" flex="1 auto" flexDirection="column" sx={{ overflow: "auto", height: "80vh" }}>
          <List>
            {filteredSegments.map((segment) => (
              <ListItem key={segment.id}>
                <Box display="flex" width="100%">
                  <Box display="flex" flex="1 auto" flexGrow="1">
                    <Box sx={{ textAlign: "left", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                      <Typography><Link target="_blank" href={`https://www.strava.com/segments/${segment.id}`}><strong>{segment.name}</strong></Link> • {segment.city}</Typography>
                      <Typography>{_t("Distance:")} {segment.distance < 1000 ? `${segment.distance}m` : `${Math.floor(segment.distance/1000)}km`}</Typography>
                      <Typography>{_t("Average grade:")} {segment.average_grade}%  {segment.climb_category > 0 && <Typography variant="span">(Cat {segment.climb_category})</Typography> }</Typography>
                    </Box>
                  </Box>

                  <Switch
                    edge="end"
                    onChange={() => handleToggle(segment.id)}
                    checked={selected.indexOf(segment.id) !== -1}
                  />
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box display="flex" flexDirection="column" m={2}>
          <Button variant="contained" onClick={onSave}>{_t("Save")}</Button>
          <Typography sx={{ marginTop: "5px", fontSize: "8px" }}>{_t("Once saved, we'll need to recompute the challenge scores, it may take some time.")}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ManageSegments;
