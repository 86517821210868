import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PeopleIcon from '@mui/icons-material/People';

import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import LandscapeIcon from '@mui/icons-material/Landscape';
import MapIcon from '@mui/icons-material/Map';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SportsIcon from '@mui/icons-material/Sports';

import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import HikingIcon from '@mui/icons-material/Hiking';
import { Chip } from '@mui/material';
import { ContentCopy, Share } from '@mui/icons-material';
import { alert } from '../utils/emitter';
import { FRONT_URL } from '../config';
import { _t } from '../utils/i18n';
import { useNavigate } from "react-router-dom";

const toHumanDuration = (secs) => {
  const duration = dayjs.duration(secs, 'seconds');

  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();

  if (months === 0 && days === 0)
    return `${hours}h`;

  if (months === 0)
    return `${days}d ${hours}h`;

  return `${months}m ${days}j ${hours}h`;
}

const ChallengeCard = ({ id, name, code, type, description, start_date, end_date, sport_types, participants_count, picture_url, full, is_public, club_id, segments, has_password, club_restricted, featured }) => {
  let Icon, display_type;

  const isEnded = dayjs().isAfter(end_date, 'day');
  const notStarted = dayjs().isBefore(start_date, 'day');

  const navigate = useNavigate();

  const FORMAT = _t('MM/DD/YYYY');

  const onCopyCode = async () => {
    // mobile share action
    try {
      return navigator.share({
        title: _t("Join my Jolkia challenge!"),
        text: _t("Join me on Jolkia to compete with me in this challenge"),
        url: FRONT_URL.clone().segment('s').segment(code).toString(),
      });
    } catch (err) {}

    // on desktop copy to clipboard
    try {
      await navigator.clipboard.writeText(FRONT_URL.clone().segment('s').segment(code).toString());
      alert(_t("Challenge url copied to clipboard!"), 'info', 3000);
    } catch (err) {
      alert(_t("Cannot copy the code"), 'error', 5000);
    }
  }

  switch (type) {
    case 'gp':
      display_type = _t('Grand Prix');
      Icon = EmojiEventsIcon;
      break;
    case 'distance':
      display_type = _t('Distance');
      Icon = MapIcon;
      break;
    case 'elevation':
      display_type = _t('Dénivelé');
      Icon = LandscapeIcon;
      break;
    case 'duration':
      display_type = _t('Durée');
      Icon = HourglassBottomIcon;
      break;
    default:
      display_type = _t('Course');
      Icon = SportsIcon;
  }

  return (
    <Card sx={{ position: "relative", width: "80vw", maxWidth: full ? "100%" : 600, marginBottom: "20px", cursor: full ? 'auto' : 'pointer' }} onClick={() => {
      if (full) return;
      navigate(`/challenge/${id}`);
    }}>
      <CardMedia
        sx={{ height: full ? 160 : 110, backgroundPosition: "left" }}
        image={picture_url || `https://picsum.photos/640/360?r=${Math.random()}`}
      />

      <Box sx={{
        display: "flex",
        position: "absolute",
        top: "5px",
        right: "5px",
      }}>
        {!is_public && (
          <Box
            sx={{
              fontSize: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              backgroundColor: "#000000",
              borderRadius: "6px",
              opacity: "0.8",
              color: "#ffffff",
              padding: "3px 5px",
            }}
          >
            {_t("Hidden")}
          </Box>
        )}

        {(club_restricted || has_password) && (
          <Box
            sx={{
              fontSize: "12px",
              display: "flex",
              marginLeft: "5px",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              backgroundColor: "#000000",
              borderRadius: "6px",
              opacity: "0.8",
              color: "#ffffff",
              padding: "3px 5px",
            }}
          >
            {_t("Protected")}
          </Box>
        )}

        {featured && (
          <Box
            sx={{
              fontSize: "12px",
              display: "flex",
              marginLeft: "5px",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              backgroundColor: "#D25C5D",
              borderRadius: "6px",
              opacity: "0.8",
              color: "#ffffff",
              padding: "3px 5px",
            }}
          >
            <Box
              component="img"
              loading='lazy'
              className="JolikaLogo"
              sx={{ height: "14px", mr: "3px" }}
              src="/jolika-logo-white.png"
            />
            {_t("Featured")}
          </Box>
        )}
      </Box>

      <CardContent sx={{ textAlign: "left", position: "relative", marginBottom: 0, paddingBottom: 0 }}>
        <Box
          sx={{
            position: "absolute",
            top: "-40px",
            height: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            right: "5px",
            backgroundColor: "#000000",
            borderRadius: "6px",
            opacity: "0.8",
            color: "#ffffff",
            padding: "3px 5px",
          }}
        >
          {sport_types.includes('Ride') && <DirectionsBikeIcon />}
          {sport_types.includes('Run') && <DirectionsRunIcon />}
          {sport_types.includes('Hike') && <HikingIcon />}

          <Box sx={{ mx: "3px" }}>•</Box>

          <Icon sx={{ color: "#ffffff" }} />
          <Box>
            {type === "gp" ? `(${segments.length}) `: null}
            {display_type}
          </Box>

          <Box sx={{ mx: "3px" }}>•</Box>

          <PeopleIcon sx={{ color: "#ffffff", mr: "3px" }} />
          <Box>{participants_count}</Box>
        </Box>
        <Typography variant="h5" component="div">
          {name}
        </Typography>
        <Box>
          <Box><Typography variant="body2" color="text.secondary">{description}</Typography></Box>
          <Box mt={2}><Typography variant="body2" color="text.secondary">{_t("From")} <strong>{dayjs(start_date).format(FORMAT)}</strong> {_t("to")} <strong>{dayjs(end_date).format(FORMAT)}</strong></Typography></Box>
        </Box>
      </CardContent>

      <CardActions>
        <>
          {!full && (
            <Box>
              <Button component={Link} to={`/challenge/${id}`} size="small">{_t("See the challenge")}</Button>
            </Box>
          )}

          {full && (
            <Box display="flex" alignItems="center" justifyContent="center" onClick={onCopyCode}>
              <Button><Share sx={{ cursor: "pointer", fontSize: '14px', marginRight: '5px' }} /> {_t("Share")}</Button>
            </Box>
          )}

          <Box sx={{ flexGrow: 1, textAlign: "right" }}>
            {isEnded && !full && (
              <Chip size="small" color="error" label="Terminé" />
            )}
            {notStarted && (
              <Typography sx={{ fontSize: "14px", marginRight: "5px" }}>{_t("In")} {toHumanDuration(dayjs(start_date).unix() - dayjs().unix())}</Typography>
            )}
            {!isEnded && !notStarted && (
              <Typography sx={{ fontSize: "14px", marginRight: "5px" }}>{toHumanDuration(dayjs(end_date).unix() - dayjs().unix())}</Typography>
            )}
          </Box>
        </>
      </CardActions>

    </Card>
  );
}

export default ChallengeCard;
