import { ArrowBack } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";

const Title = ({ children, returnAction, rightComponent, variant = "h6" }) => {
  return (
    <Box display="flex">

      <Box display="flex" sx={{ width: "35px" }}>
        {returnAction && (
          <Button onClick={returnAction}><ArrowBack sx={{ mr: "5px" }}/></Button>
        )}
      </Box>

      <Box display="flex" alignItems="center" justifyContent="center" flex="1 auto">
        <Typography variant={variant}>{children}</Typography>
      </Box>

      <Box sx={{ width: "35px" }} display="flex">
        {rightComponent}
      </Box>

    </Box>
  )
};

export default Title;
