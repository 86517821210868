import { Button, Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getStorage } from "../utils";
import { _t } from "../utils/i18n";

const Email = () => {
  const user = getStorage('user');
  const session = getStorage('session');
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!session) return;
    if (user.email) return;

    setShow(true);
  }, []);

  if (!show) return null;

  return (
    <Box display="flex" justifyContent="center" alignItems="center" className="email" sx={{
      position: "fixed",
      padding: "5px 10px",
      left: "5px",
      bottom: "10px",
      borderRadius: "6px",
      background: "rgba(0, 0, 0, .9)",
      maxWidth: "calc(100vw - 30px)",
    }}>
      <Typography sx={{
        color: "white",
        fontSize: "12px",
      }}>
       {_t("We'd like to know your email to notify you challenges updates.")}
      </Typography>
      <Button
        onClick={() => {
          setShow(false);
          navigate("/profile");
        }}
        sx={{
          marginRight: 0,
          paddingRight: 0,
          minWidth: "auto",
          fontSize: "12px",
        }}
      >
        {_t("go").toUpperCase()}
      </Button>
    </Box>
  );
}

export default Email;
