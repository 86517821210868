import { Link, useLocation } from "react-router-dom";
import { _t } from "../utils/i18n";

const { Box, Typography, Button } = require("@mui/material");

const FooterOverlay = () => {
  const location = useLocation();

  if (["/about", "/"].includes(location.pathname))
    return null;

  return (
    <Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          height: "125px",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          zIndex: 1001,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "white",
            }}
          >
            <Box sx={{ fontSize: "24px", fontWeight: "bold" }}>
              <Box mb={2} sx={{ padding: "0 3px" }}>
                <Typography>{_t("Connect now with your Strava account to join a challenge!")}</Typography>
              </Box>
              <Button component={Link} to="/about" variant="contained" size="small">{_t("Read more")}</Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
};

export default FooterOverlay;
