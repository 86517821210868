import dayjs from "dayjs";
import { TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch, Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { API_URL } from "../config";
import xhr from "../xhr";
import SelectMultiple from "../Dumbs/SelectMultiple";
import { Link, useNavigate, useParams } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { alert } from "../utils/emitter";
import { _t } from "../utils/i18n";
import { ChevronLeft, Help } from "@mui/icons-material";
// import Tooltip from "../Dumbs/Tooltip";

const AVAILABLE_SPORT_TYPES = [
  "Run",
  "Ride",
  "TrailRun",
  "VirtualRide",
  "Hike",
];

const Create = () => {
  const navigate = useNavigate();
  const params = useParams();
  const DATE_FORMAT = _t('MM/DD/YYYY');

  const isEdit = !!params.id;

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [is_public, setIsPublic] = useState(true);
  const [start, setStart] = useState(dayjs());
  const [stop, setStop] = useState(dayjs());
  const [picture_url, setPictureUrl] = useState('');
  const [sport_types, setSportTypes] = useState([]);
  const [challenge, setChallenge] = useState(null);

  const [clubs, setClubs] = useState([]);
  const [club_id, setClubId] = useState(null);
  const [password, setPassword] = useState(null);
  const [club_restricted, setClubRestricted] = useState(false);
  const [showSecurity, setShowSecurity] = useState(false);

  useEffect(() => {
    const fetchClubs = async () => {
      const data = await xhr(API_URL.clone().segment('user').segment('clubs').toString(), {});

      if (!data.clubs)
        console.log('error while retrieving user clubs', data);

      setClubs(data.clubs);
    };

    fetchClubs();
  }, []);

  useEffect(() => {
    if (!isEdit) return;

    const fetchData = async () => {
      const url = API_URL
        .clone()
        .segment('challenges')
        .segment(params.id)
        .toString();

      try {
        const data = await xhr(url);

        if (data.error) {
          alert(data.errorMessage, 'error');
          return;
        }

        setChallenge(data.challenge);
      } catch (e) {
        alert(_t("Error while retrieving challenge"), 'error');
      }
    };

    fetchData();
  }, [isEdit]);

  useEffect(() => {
    if (!challenge) return;

    setTitle(challenge.name);
    setDescription(challenge.description);
    setType(challenge.type);
    setIsPublic(challenge.is_public);
    setStart(dayjs(challenge.start_date));
    setStop(dayjs(challenge.end_date));
    setPictureUrl(challenge.picture_url);
    setSportTypes(challenge.sport_types);
    setClubId(challenge.club_id);
    setClubRestricted(challenge.club_restricted);
    setShowSecurity(challenge.club_restricted || challenge.password);

    // password is not hashed because we are the owner so exposed by the API
    setPassword(challenge.password);
  }, [challenge]);

  const onCreate = async () => {
    const fields = {
      title,
      description,
      type,
      is_public,
      club_id,
      password,
      start: start.format(DATE_FORMAT),
      stop: stop.format(DATE_FORMAT),
      picture_url,
      sport_types,
      club_id,
      password,
      club_restricted,
    };

    let url

    if (isEdit) {
      url = API_URL
        .clone()
        .segment('challenges')
        .segment(challenge.id)
        .toString();
    } else {
      url = API_URL
        .clone()
        .segment('challenges')
        .toString();
    }

    try {
      const data = await xhr(url, {
        method: 'POST',
        body: JSON.stringify(fields),
      });

      if (data.error && data.errorCode === 'missing_fields') {
        console.log('là')
        alert(_t("Some required fields are missing"), 'error', 5000);
        return;
      }

      if (data.error) {
        console.log('ici', data.errorMessage)
        alert(data.errorMessage, 'error');
        return;
      }

      alert(isEdit ? _t("Challenge successfully edited!") : _t("Challenge successfully created!"), 'congrats', 5000);
      navigate(`/challenge/${data.challenge.id}`);

    } catch (e) {
      alert(_t("Error while creating challenge"), 'error', 5000);
    }
  };

  if (isEdit && !challenge)
    return null;

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box sx={{ width: '80vw', maxWidth: '600px', position: "relative" }}>
        <Box>
          <Typography variant="h5">{isEdit ? _t("Edit challenge") : _t("Create a new challenge")}</Typography>

          {isEdit && (
            <Button component={Link} to={`/challenge/${challenge.id}`} sx={{ position: "absolute", top: 0, right: 0, minWidth: 0, marginLeft: "10px", padding: "5px" }} variant="outlined">
              <CloseIcon sx={{ padding: 0, margin: 0, fontSize: "14px" }} size="small" />
            </Button>
          )}
        </Box>

        <Box mt={2}>
          <TextField
            label={_t("Title")}
            value={title}
            fullWidth
            onChange={(e) => setTitle(e.target.value)}
            variant="outlined"
          />
          <TextField
            sx={{ marginTop: "15px" }}
            label={_t("Description")}
            value={description}
            fullWidth
            onChange={(e) => setDescription(e.target.value)}
            variant="outlined"
          />

          <FormControl
            fullWidth
            sx={{ marginTop: "15px" }}
          >
            <InputLabel>{_t("Challenge type")} {isEdit ? _t("(not editable)") : ""}</InputLabel>
            <Select
              sx={{ textAlign: 'left' }}
              value={type}
              label={_t("Type")}
              onChange={event => setType(event.target.value)}
              disabled={isEdit}
            >
              <MenuItem value="gp">{_t("Grand Prix")}</MenuItem>
              <MenuItem value="distance">{_t("Distance")}</MenuItem>
              <MenuItem value="duration">{_t("Duration")}</MenuItem>
              <MenuItem value="elevation">{_t("Elevation")}</MenuItem>
            </Select>
          </FormControl>

          <Box mt={2}>
            <SelectMultiple
              label={_t("Sport types")}
              initialValue={sport_types}
              options={AVAILABLE_SPORT_TYPES}
              onChange={setSportTypes}
            />
          </Box>

          <Box mt={2} display="flex">
            <MobileDatePicker
              label={_t("From")}
              inputFormat={DATE_FORMAT}
              value={start}
              onChange={event => setStart(event)}
              renderInput={(params) => <TextField sx={{ width: '48%' }} {...params} />}
            />
            <Box flex="1 auto"></Box>
            <MobileDatePicker
              label={_t("To")}
              inputFormat={DATE_FORMAT}
              value={stop}
              onChange={event => setStop(event)}
              renderInput={(params) => <TextField sx={{ width: '48%' }} {...params} />}
            />
          </Box>

          <Box mt={2}>
            <TextField
              label={_t("Illustration picture (optional)")}
              placeholder={`https://picsum.photos/640/360?r=${Math.random()}`}
              value={picture_url}
              fullWidth
              onChange={(e) => setPictureUrl(e.target.value)}
              variant="outlined"
            />
          </Box>

          <FormControl
            fullWidth
            sx={{ marginTop: "15px" }}
          >
            {!club_id && (<InputLabel id="demo-simple-select-label">{_t("Link with Strava club")}</InputLabel>)}
            <Select
              sx={{ textAlign: 'left' }}
              value={club_id}
              label={_t("Club")}
              onChange={event => {
                setClubId(event.target.value)
                if (!event.target.value)
                  setClubRestricted(false)
              }}
            >
              {clubs && clubs.length > 0 && club_id && (
                <MenuItem value={null}>{_t("None")}</MenuItem>
              )}
              {clubs && clubs.map(({ id, name }) => (
                <MenuItem value={id}>{name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box sx={{ position: "relative", display: "flex", width: "100%" }}>
            <Box mt="10px" onClick={() => setShowSecurity(!showSecurity)} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                {_t("Security options")}
                <ChevronLeft sx={{
                  transform: showSecurity ? "rotate(90deg)" : "rotate(-90deg)",
                }} />
            </Box>
          </Box>

          {showSecurity && (
            <Box mt={2} display="flex" flexDirection="column">

              {/* <Box> */}
              <Box display="flex">
                <FormControlLabel
                  control={<Switch checked={is_public} onChange={event => setIsPublic(event.target.checked)}/>}
                  label={_t("Public")}
                />
              </Box>
                {/* <Tooltip title="Help">
                  <Help sx={{ fontSize: "14px", marginLeft: "5px" }} />
                </Tooltip> */}
              {/* </Box> */}

              <Box display="flex">
                <FormControlLabel
                  control={<Switch disabled={!club_id && !club_restricted} checked={club_restricted} onChange={event => setClubRestricted(event.target.checked)}/>}
                  label={_t("Club restricted")}
                />
              </Box>

              <TextField
                sx={{ marginTop: "15px" }}
                label={_t("Protect by password")}
                value={password}
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
              />

            </Box>
          )}

        </Box>

        <Box my={1}>
          <Button onClick={onCreate} variant="contained" sx={{ marginTop: "15px" }}>{isEdit ? _t("Edit") : _t("Create")}</Button>
        </Box>

      </Box>
    </Box>
  )
};

export default Create;
