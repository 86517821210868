import EventEmitter from "eventemitter3";

const getEmitter = () => {
  const emitter = new EventEmitter();
  return emitter;
};

const emitter = getEmitter();

export const alert = (message, type = "success", timeout, action = null) => {
  emitter.emit("alert", { message, type, timeout, action });
}

window.alert = alert;

export default emitter;
