import { AddCircleOutline } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Title from "../components/Title";
import { API_URL } from "../config";
import { getStorage } from "../utils";
import { _t } from "../utils/i18n";
import xhr from "../xhr";
import ClubCard from "./ClubCard";

const Clubs = () => {
  const session = getStorage('session');
  const navigate = useNavigate();
  const [clubs, setClubs] = useState([]);

  useEffect(() => {
    const fetchClubs = async () => {
      const data = await xhr(API_URL.clone().segment('clubs').toString(), {});
      setClubs(data.clubs);
    };

    fetchClubs();
  }, []);

  return (
    <Box>
      <Title
        rightComponent={
          <>
            {session && <Button component={Link} to="/clubs/add" sx={{ minWidth: 0, padding: 0, margin: 0 }}><AddCircleOutline /></Button>}
          </>
        }
      >
        {_t("Clubs")}
      </Title>

      {clubs.map((club) => (
        <Box key={club.strava_id} mt={4} px={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Box>
            <ClubCard
              club={{
                ...club.data,
                description: club.description,
                owner_id: club.owner_id
              }}
              actions={[
                {
                  label: _t('View challenges'),
                  onClick: () => navigate(`/challenges?club_id=${club.strava_id}`),
                },
              ]} />
          </Box>
        </Box>
      ))}

    </Box>
  );
}

export default Clubs;
