import { useEffect, useState } from 'react';

import { API_URL } from '../config';
import xhr from '../xhr';
import ChallengeCard from './ChallengeCard';
import { Alert, Box, Button, CircularProgress, Divider, Typography } from '@mui/material';

import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import Search from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';

import EmptyChallenges from './Empty';
import { useNavigate } from 'react-router-dom';
import { _t } from '../utils/i18n';
import { alert } from '../utils/emitter';
import URI from 'urijs';

const All = () => {
  const navigate = useNavigate();
  const query = new URI().search(true);

  const [loading, setLoading] = useState(true);
  const [club, setClub] = useState(null);
  const [type, setType] = useState(query.type || null);
  const [challenges, setChallenges] = useState([]);
  const [filters, setFilters] = useState({});

  const changeFilter = (filter) => {
    const newFilters = { ...filters, [filter]: !filters[filter] };

    if (!newFilters.ride && !newFilters.run) {
      setFilters({});
      return;
    }

    setFilters(newFilters);
  };

  useEffect(() => {
    const fetchChallenges = async () => {
      const data = await xhr(API_URL.clone().segment('challenges').toString(), {});

      if (data.error) {
        alert(data.error, 'error', 5000);
        return;
      }

      setLoading(false);
      setChallenges(data.challenges);
    };

    const fetchClub = async () => {
      const data = await xhr(API_URL.clone().segment('clubs').segment(`${query.club_id}`).toString(), {});

      if (data.club) setClub(data.club);
    };

    if (query.club_id) fetchClub();

    setLoading(true);
    fetchChallenges();
  }, []);

  if (!loading && challenges.length === 0)
    return <EmptyChallenges />;

  let filteredActiveChallenges = challenges
    .filter((challenge) => {
      if (new Date(challenge.end_date) < new Date())
        return false;

      if (Object.keys(filters).length === 0) return true;

      if (challenge.sport_types.includes('Ride') && filters.ride)
        return true;

      if (challenge.sport_types.includes('Run') && filters.run)
        return true;

      return false;
    })
    .sort((a, b) => {
      if (a.featured && !b.featured) return -1;
      if (!a.featured && b.featured) return 1;

      return new Date(a.end_date) - new Date(b.end_date)
    });

  if (club)
    filteredActiveChallenges = filteredActiveChallenges.filter((challenge) => challenge.club_id === club.strava_id);

  if (type)
    filteredActiveChallenges = filteredActiveChallenges.filter((challenge) => challenge.type === type);

  return (
    <Box pt={2}>
      <Typography variant="h4">{_t("Public active challenges")}</Typography>

      <Box display="flex" justifyContent="center" alignItems="center" sx={{ margin: "10px auto", width: "80vw", maxWidth: 600 }}>
        <Box sx={{ display: "flex", flex: "1 auto" }}>
          <Box onClick={() => changeFilter('ride')} sx={{ padding: "3px" }}>
            <Button sx={{ fontSize: "10px", padding: "3px" }} variant={filters.ride ? 'contained' : 'outlined'} size="small">
              <DirectionsBikeIcon sx={{ fontSize: "15px", height: "15px", marginRight: "3px" }} />
              {_t('Ride')}
              {filters.ride && <CancelIcon sx={{ fontSize: "15px", height: "15px", marginLeft: "3px" }} />}
            </Button>
          </Box>
          <Box onClick={() => changeFilter('run')} sx={{ padding: "3px" }}>
            <Button sx={{ fontSize: "10px", padding: "3px" }} variant={filters.run ? 'contained' : 'outlined'} size="small">
              <DirectionsRunIcon sx={{ fontSize: "15px", height: "15px", marginRight: "3px" }} />
              {_t('Run')}
              {filters.run && <CancelIcon sx={{ fontSize: "15px", height: "15px", marginLeft: "3px" }} />}
            </Button>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="center" >
          <Button onClick={() => navigate('/challenges/search')} sx={{ fontSize: "10px", padding: "3px" }} size="small">{_t("Search")} <Search /></Button>
        </Box>

      </Box>

      {club && (
        <Box px={4} mb={2}>
          <Alert severity="info" onClose={() => setClub(null)}>
            {_t("Challenges are filtered by club '{name}'", { name: club.name })}
          </Alert>
        </Box>
      )}

      {type && (
        <Box px={4} mb={2}>
          <Alert severity="info" onClose={() => setType(null)}>
            {_t("Challenges are filtered by type '{type}'", { type })}
          </Alert>
        </Box>
      )}

      {loading && (
        <Box sx={{ display: 'flex', height: '50vh', justifyContent: "center", alignItems: "center" }}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <CircularProgress />
            {_t("Loading...")}
          </Box>
        </Box>
      )}

      {!loading && filteredActiveChallenges.length === 0 && (
        <Box mt={5} px={4}><Typography variant="h6">{_t("There are no challenges that match your search criteria.")}</Typography></Box>
      )}

      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
        {filteredActiveChallenges.map((challenge, i) => <ChallengeCard key={i} {...challenge} />)}
      </Box>

    </Box>
  );
}

export default All;
