import URI from "urijs";

export const FRONT_URL = new URI('https://jolkia.com');
export const API_URL = new URI('https://jolkia.com/api');

export const STRAVA_AUTH_URL = new URI('https://www.strava.com/oauth/authorize');
export const STRAVA_CLIENT_ID = "94534";

export const STRAVA_REDIRECT_URL = new URI('https://jolkia.com/connect');

export const SENTRY_URL = "https://49849848ba7646d199f8f0f7b37ccc53@o4504837022351360.ingest.sentry.io/4504837044043776";

export const MAPBOX_TOKEN = "pk.eyJ1IjoiZ3VpbGxhdW1lcG90aWVyIiwiYSI6ImNsZnRmaGF3MjAwZ2YzZHBkaXZjbWlmMTcifQ.zAKIX-n7V85c7ca93uvTtQ";
