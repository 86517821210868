import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { _t } from "../utils/i18n";

const MustLogin = () => {
  return (
    <Box sx={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: 'rgba(0, 0, 0, 0.8)',
      color: "white",
      padding: 2,
      zIndex: 1000,
    }}>
      <Typography variant="h4">{_t("You must log in to access that page")}</Typography>
    </Box>
  )
};

export default MustLogin;
