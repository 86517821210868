import { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import { API_URL } from '../config';
import Challengers from './Challengers';
import Leaderboard from './Leaderboard';
import Segments from './Segments';
import { getStorage } from '../utils';
import xhr from '../xhr';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';

import { Link } from 'react-router-dom';

import ChallengeCard from './ChallengeCard';
import { CircularProgress, Modal, TextField } from '@mui/material';
import { alert } from '../utils/emitter';
import { _t } from '../utils/i18n';
import Loader from '../components/Loader';
import Map from './Map';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BasicTabs = ({ challenge, challengers, user }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => setValue(newValue);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={_t("Leaderboard")} {...a11yProps(0)} sx={{ fontSize: "12px", padding: "5px" }} />
          <Tab label={_t("Participants ({count})", { count: challengers.length })} {...a11yProps(1)} sx={{ fontSize: "12px", padding: "5px" }} />
          {challenge.type === "gp" && (
            <Tab label={_t("Segments ({count})", { count: challenge.segments.length || 0 })} {...a11yProps(2)} sx={{ fontSize: "12px", padding: "5px" }} />
          )}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Leaderboard challenge={challenge} challengers={challengers} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Challengers challenge={challenge} challengers={challengers} />
      </TabPanel>

      {challenge.type === "gp" && (
        <TabPanel value={value} index={2}>
          <Box sx={{ position: 'relative' }}>

            {[challenge.user_id, challenge.admin_id].includes(user?.id) && (
              <Box sx={{ position: 'absolute', top: '-20px', right: '-20px', zIndex: 3 }}>
                <Button variant="outlined" size="small" sx={{ height: '20px', minWidth: 0, minHeight: 0, ml: '2px', fontSize: "10px", padding: "3px" }} component={Link} to={`/challenges/${challenge.id}/segments/edit`}>{_t("Manage")}</Button>
              </Box>
            )}

            <Segments challenge={challenge} challengers={challengers} />
          </Box>
        </TabPanel>
      )}
    </Box>
  );
}

let once = false;

const Challenge = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [error, setError ] = useState(null);
  const [challenge, setChallenge] = useState(null);
  const [challengers, setChallengers] = useState([]);
  const [segments, setSegments] = useState(null);

  const [club, setClub] = useState(null);
  const [joinNotice, setJoinNotice] = useState(false);
  const [isJoining, setIsJoining] = useState(false);
  const [password, setPassword] = useState('');

  const user = getStorage('user');

  const fetchClub = async () => {
    const data = await xhr(API_URL.clone().segment('user').segment('clubs').segment(`${challenge.club_id}`).toString(), {});
    return data.club;
  };

  const onJoin = async () => {
    if (!user) {
      navigate('/connect');
      return;
    }

    setIsJoining(true);

    if (challenge.club_id && !club) {
      const club = await fetchClub();
      setClub(club);

      if (club.membership === 'none') {
        setIsJoining(false);
        setJoinNotice(true);
        return;
      }
    }

    if (challenge.has_password) {
      setIsJoining(false);
      setJoinNotice(true);
      return;
    }

    await join();
  };

  const join = async () => {
    const data = await xhr(API_URL.clone().segment('challenges').segment(challenge.id).segment('join').toString(), {
      method: 'POST',
      body: JSON.stringify({
        password,
      }),
    });

    if (data.error && data.errorCode === 'wrong_password') {
      alert(_t("Wrong password"), 'error', 5000);
      return;
    }

    if (data.error && data.errorCode === 'club_protected') {
      alert(_t("You don't belong to the expected club"), 'info', 5000);
      return;
    }

    if (data.error) {
      console.log('unhandled error', data);
      return;
    }

    setIsJoining(false);
    setJoinNotice(false);
    setChallengers(data.challengers);

    alert(_t("You joined the challenge!"), 'success', 5000);
  };

  const isChallenger = challengers.find(c => c?.user_id === user?.id);

  useEffect(() => {
    if (!(isChallenger && user.last_sync === null)) return;
    if (once) return;

    once = true;
    alert(
      _t("Make sure to synchronize your Strava activities once. Click here to go to My Activities."),
      'info',
      15000,
      () => navigate('/sync')
    );

  }, [challengers]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await xhr(API_URL.clone().segment('challenges').segment(params.id).toString());

        if (data.error) {
          setError(data.error);
          return;
        }

        setChallenge(data.challenge);
        setChallengers(data.challengers);
      } catch (e) {
        setError('Erreur lors de la récupération du challenge.');
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (challenge?.type !== 'gp') return;

    const fetchData = async () => {
      try {
        const url = API_URL
          .clone()
          .segment('challenges')
          .segment(`${challenge.id}`)
          .segment('segments')
          .toString();

        const data = await xhr(url);

        if (data.error) {
          setError(data.error);
          return;
        }

        setSegments(data.segments);
      } catch (e) {
        setError('Erreur lors de la récupération des segments.');
      }
    };

    fetchData();
  }, [challenge]);

  if (error) {
    return (
      <Box>
        <h1>Challenge</h1>
        <Box>{_t("An error occured:")} {error}</Box>
      </Box>
    );
  }

  if (challenge === null && error === null)
    return <Loader />

  const isEnded = new Date(challenge.end_date) < new Date();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "80vw" }}>

        {joinNotice && (
          <Modal
            open={true}
            onClose={() => setJoinNotice(false)}
            >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '300px',
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}>
              <h2 id="modal-modal-title">{_t("This challenge is protected!")}</h2>
              <Box id="modal-modal-description">
                {challenge.club_id && !club && (
                 <Box>{_t("Loading...")}</Box>
                )}
                {challenge.club_id && (
                 <Box>{_t("This challenge is only for the following Strava club:")} <Link target="_blank" to={`https://www.strava.com/clubs/${club.url}`}>{club.name}</Link></Box>
                )}
                {challenge.password && challenge.club_id && (
                  <Box my={2}>{_t("and requires a password:")}</Box>
                )}
                {challenge.password && (
                  <Box>
                    <TextField
                      sx={{ marginTop: "15px" }}
                      label={_t("Password")}
                      value={password}
                      fullWidth
                      onChange={(e) => setPassword(e.target.value)}
                      variant="outlined"
                    />

                    <Box my={1}>
                      <Button onClick={join} variant="contained" sx={{ marginTop: "15px" }}>{_t("Join")}</Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Modal>
        )}

        <Box sx={{ display: "flex", width: "100%", mb: 2, alignItems: "center" }}>
          <Box sx={{ display: "flex", flex: "1 auto" }}>
            <Button onClick={() => navigate(-1)}><ArrowBackIcon sx={{ mr: "5px" }}/> {_t("Back")}</Button>
          </Box>
          <Box sx={{ display: "flex", flex: "none" }}>
            {!isEnded && isChallenger && (
              <Chip size="small" color="success" label="Inscrit !" />
            )}
            {!isEnded && !isChallenger && !isJoining && (
              <Button onClick={onJoin}>{_t("Participate")}</Button>
            )}
            {isJoining && (
              <CircularProgress size="20px" />
            )}
            {isEnded && (
              <Chip size="small" color="error" label="Terminé" />
            )}
            {user?.id === challenge?.user_id && (
              <Button component={Link} to={`/challenges/${challenge.id}/edit`} sx={{ minWidth: 0, marginLeft: "10px", padding: "5px" }} variant="outlined">
                <EditIcon sx={{ padding: 0, margin: 0, fontSize: "14px" }} size="small" />
              </Button>
            )}
          </Box>
        </Box>

        <ChallengeCard {...challenge} full={true} />

        {challenge.type === 'gp' && (
          <Box sx={{
            display: "flex",
            width: "100%",
            minHeight: "100px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            position: "relative",
            background: "#fff",
            marginBottom: "15px",
          }}>
            {segments && segments.length > 0 && (<Map key={Math.random()} challenge={challenge} segments={segments} />)}
            {segments !== null && segments.length === 0 && (<Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Typography variant="h6">{_t("No segments yet to draw map.")}</Typography>
                {[challenge.user_id, challenge.admin_id].includes(user?.id) && (
                  <Button variant="outlined" size="small" sx={{ height: '20px', minWidth: 0, minHeight: 0, ml: '2px', fontSize: "10px", padding: "3px" }} component={Link} to={`/challenges/${challenge.id}/segments/edit`}>{_t("Add your first segment")}</Button>
                )}
              </Box>
            </Box>)}
          </Box>
        )}

        {!isChallenger && !challenge.is_public ? (
          <Box display="flex" justifyContent="center" alignItems="center" width="100%">
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>{_t("This challenge is private")}</Typography>
          </Box>
        ) : (
          <BasicTabs challenge={challenge} challengers={challengers} user={user} />
        )}

      </Box>
    </Box>
  )
}

export default Challenge;
