import { AddCircleOutline } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Title from "../components/Title";
import { API_URL } from "../config";
import { alert } from "../utils/emitter";
import { _t } from "../utils/i18n";
import xhr from "../xhr";
import ClubCard from "./ClubCard";

const Add = () => {
  const navigate = useNavigate();
  const [clubs, setClubs] = useState([]);

  useEffect(() => {
    const fetchClubs = async () => {
      const data = await xhr(API_URL.clone().segment('user').segment('clubs').toString(), {});
      setClubs(data.clubs);
    };

    fetchClubs();
  }, []);

  const onAdd = async (club) => {
    const data = await xhr(API_URL.clone().segment('clubs').segment('sync').segment(`${club.id}`).toString(), {
      method: 'POST',
    });

    if (!data.club) {
      alert(_t('Error while adding club'), 'error', 5000);
      return;
    }

    alert(_t('Club added'), 'success', 5000);
    navigate(`/clubs`);
  };

  return (
    <Box>
      <Title
        returnAction={() => navigate(-1)}
      >{_t("List your club")}</Title>

      <Box mt={4} px={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Typography mb={2}>{_t("Add a Strava club to Jolkia to give it exposure and list the challenges associated to it.")}</Typography>

        {clubs.map((club) => {
          const actions = [];

          actions.push({
            label: _t('Add'),
            onClick: () => onAdd(club),
          });

          return (
            <Box mb={2} key={club.id}>
              <ClubCard club={club} actions={actions} />
            </Box>
          );
        })}

      </Box>

    </Box>
  );
}

export default Add;
