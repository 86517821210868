import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../config";
import { alert } from "../utils/emitter";
import { _t } from "../utils/i18n";
import xhr from "../xhr";

const ShortCode = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { code } = params;

  useEffect(() => {
    const fetchData = async () => {
      const url = API_URL.clone().segment('challenges').segment('search').addSearch('code', code).toString();
      const data = await xhr(url, {});

      if (data.error) {
        alert(_t("This shortcode is invalid"), 'error');
        navigate('/');
        return;
      }

      navigate(`/challenge/${data.challenge.id}`);
    };

    fetchData();
  }, []);

  return <CircularProgress />;
};

export default ShortCode;
