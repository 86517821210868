import { Avatar } from '@mui/material';
import { Box } from '@mui/system';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';

import { API_URL } from '../config';
import { _t } from '../utils/i18n';
import xhr from '../xhr';
import ChallengerOptions from './ChellengerOptions';

const Challengers = ({ challenge }) => {
  const [error, setError ] = useState(null);
  const [challengers, setChallengers] = useState([]);
  const once = useRef(false);

  const FORMAT = _t('MM/DD/YYYY');

  const onDelete = (challenger) => {
    setChallengers(challengers.filter(c => c.id !== challenger.id));
  };

  useEffect(() => {
    if (once.current) return;

    once.current = true;
    const fetchData = async () => {
      const url = API_URL
        .clone()
        .segment('challenges')
        .segment(`${challenge.id}`)
        .segment('challengers')
        .toString();

      try {
        const data = await xhr(url);

        if (data.error) {
          setError(data.error);
          return;
        }

        setChallengers(data.challengers);
      } catch (e) {
        setError(_t("Error while fetching challengers"));
      }
    };

    fetchData();
  }, []);

  if (error) {
    return (
      <Box>
        <Box>{_t("An error occurred while fetching challengers: {error}", { error })}</Box>
      </Box>
    );
  }

  return (
    <Box style={{ listStyle: "none" }}>
      {challengers.map((challenger, i) => {
        return <Box mb={2} key={i}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ display: "flex", flex: "1 auto" }}>
              <Avatar sx={{ mr: 2 }} alt={`${challenger.first_name} ${challenger.last_name}`} src={challenger.avatar} />
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <span><strong>{challenger.first_name} {challenger.last_name}</strong></span>
                <span>{_t("Registered on")} {dayjs(challenger.created_on).format(FORMAT)}</span>
              </Box>
            </Box>
            <Box>
              <ChallengerOptions
                challenge={challenge}
                challenger={challenger}
                onDelete={() => onDelete(challenger)} />
            </Box>
          </Box>
        </Box>
      })}
    </Box>
  );
}

export default Challengers;
