import { getStorage } from "./utils";

const xhr = async (url, options) => {
  const session = getStorage('session');

  const headers = {
    'Content-Type': 'application/json',
  };

  if (session)
    headers['X-Session'] = session.id;

  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers,
    ...options,
  });

  if (response.status < 200 || response.status >= 300) {
    const errorResponse = {
      code: response.status,
      error: response.statusText,
      errorMessage: null,
    };

    try {
      const errorMessage = await response.json();
      errorResponse.errorMessage = errorMessage?.error;
      errorResponse.errorCode = errorMessage?.code;
    } catch (e) {}

    return errorResponse;
  }

  try {
    return await response.json();
  } catch (e) {}

  return false;
};

export default xhr;
