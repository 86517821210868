import { Avatar, Box } from '@mui/material';
import humanizeDuration from '../utils/humanizeDuration';
import { _t } from '../utils/i18n';

const Leaderboard = ({ challenge, challengers }) => {

  const leaderboard = [];

  challengers.forEach(({ distance, elevation, duration, first_name, last_name, avatar, last_sync, score }) => {
    leaderboard.push({ distance, elevation, duration, first_name, last_name, avatar, last_sync, score });
  });

  switch (challenge.type) {
    case "distance":
    case "elevation":
    case "duration":
      leaderboard.sort((a, b) => b[challenge.type] - a[challenge.type]);
      break;
    case "gp":
      leaderboard.sort((a, b) => b.score - a.score);
    default:
      break;
  }

  return (
    <Box>
      <Box style={{ listStyle: "none", paddingLeft: 0 }}>
        {leaderboard.map(({ distance, elevation, duration, first_name, last_name, avatar, score }, i) => {

          return <Box mb={2} key={i}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box mr={2}><strong>#{i+1}</strong></Box>
              {/* <Box mr={2} display="flex" justifyContent="center" alignItems="center" style={{ width: "34px", height: "34px", position: "relative" }}>
                {i <= 10 && (
                  <>
                    <Box style={{ position: "absolute", color: "white", fontSize: "12px" }}>{i+1}</Box>
                    <EmojiEventsIcon sx={{ color: "gold", height: "34px", width: "34px" }} />
                  </>
                )}
              </Box> */}
              <Avatar sx={{ mr: 2 }} alt={`${first_name} ${last_name}`} src={avatar} />
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <span><strong>{first_name} {last_name}</strong></span>

                {challenge.type === "distance" && (
                  <span>{_t("Distance")} {(distance / 1000).toFixed(2)} km</span>
                )}

                {challenge.type === "elevation" && (
                  <span>{_t("Elevation")} {Math.round(elevation)} m</span>
                )}

                {challenge.type === "duration" && (
                  <span>{_t("Duration")} {humanizeDuration(Math.round(duration))}</span>
                )}

                {challenge.type === "gp" && (
                  <span>{score}pts</span>
                )}

              </Box>

              {/* {last_sync && (
                <Box sx={{ flexGrow: 1 }} >
                  <Typography sx={{ fontSize: 12 }}>Dernière synchro le <strong>{dayjs(last_sync).format("DD/MM/YYYY HH:mm")}</strong></Typography>
                </Box>
              )} */}
            </Box>
          </Box>

        })}
      </Box>
    </Box>
  );
}

export default Leaderboard;
