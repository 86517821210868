import { Link } from 'react-router-dom';
import { _t } from './utils/i18n';
const { Box, Typography, List, ListItem, Button } = require("@mui/material");

const About = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "100%" }}>

        <Box display="flex" flexDirection="column" sx={{ m: 4, maxWidth: "800px" }}>
          <Typography variant="h4">
            {_t("What is this app ?")}
          </Typography>
          <Typography sx={{ marginTop: "20px", textAlign: "left" }} variant="body1">
            {_t("Jolkia is an app that allows you to participate in sport challenges with your friends or club members.")}
          </Typography>
          <Typography sx={{ marginTop: "10px", textAlign: "left" }} variant="body1">
            {_t("It is possible to compete in running or cycling (or both). There are different types of challenges:")}
          </Typography>
          <List sx={{ marginTop: "10px" }}>
            <ListItem><Typography variant="body1"><strong>{_t("Distance")}</strong> : {_t("the participant who will have travelled the most kilometers wins")}</Typography></ListItem>
            <ListItem><Typography variant="body1"><strong>{_t("Duration")}</strong> : {_t("the participant who has spent the most time in activity wins")}</Typography></ListItem>
            <ListItem><Typography variant="body1"><strong>{_t("Elevation")}</strong> : {_t("the participant who will have covered the most positive altitude difference wins")}</Typography></ListItem>
            <ListItem><Typography variant="body1"><strong>{_t("Grand Prix")}</strong> : {_t("the participant who will have obtained the best times on a maximum of segments wins")}</Typography></ListItem>
          </List>

          <Box mt={4} />
          <Typography variant="h4">{_t("How does it works?")}</Typography>
          <Typography sx={{ marginTop: "20px", textAlign: "left" }} variant="body1">{_t("Jolkia requires your permission once to connect to your Strava account, and uses it to synchronize your activities. Then, depending on the challenges you participate in, your time and effort are tallied to rank you.")}</Typography>
          <Typography sx={{ marginTop: "10px", textAlign: "left" }} variant="body1">{_t("To join a challenge, simply look at the public challenges available (or have a link to a private challenge), and click on the \"Join\" button.")}</Typography>
          <Typography sx={{ marginTop: "10px", textAlign: "left" }} variant="body1">{_t("You will never be asked for your Strava password. You can turn off the sync at any time from your Strava account.")}</Typography>

          <Box mt={4} />
          <Typography variant="h4">{_t("How do I synchronize my data?")}</Typography>
          <Typography sx={{ marginTop: "20px", textAlign: "left" }} variant="body1">{_t("The synchronization of your data is done from the \"My activities\" page.")}</Typography>
          <Typography sx={{ marginTop: "10px", textAlign: "left" }} variant="body1">{_t("A first manual sync will retrieve your last 50 activities. Then, either you continue to manually sync your activities after each outing, or you activate the automatic sync which will automatically retrieve your activity once published on Strava (recommended)")}</Typography>

          <Box mt={4} />
          <Typography variant="h4">{_t("Does it cost anything?")}</Typography>
          <Typography sx={{ marginTop: "20px", textAlign: "left" }} variant="body1">{_t("Currently, no, the whole application is free. Depending on its success, it is not excluded that some options will have to be paid for by challenge organizers.")}</Typography>


          <Box mt={4} />
          <Typography variant="h4">{_t("Who makes this application?")}</Typography>
          <Typography sx={{ marginTop: "20px", textAlign: "left" }} variant="body1">{_t("This application is developed on his free time by")} <a href="https://www.strava.com/athletes/4312866" target="_blank">Guillaume Potier</a>. {_t("The servers and data are hosted in France by the hosting provider")} <a href="https://www.scaleway.com/" target="_blank">Scaleway</a></Typography>
        </Box>

        <Box my={4}>
          <Button component={Link} to="/">{_t("Back home")}</Button>
        </Box>
    </Box>
  );
};

export default About;
