import { Button } from '@mui/material';
import { setStorage } from './utils';
import { _t } from './utils/i18n';

const Logout = () => {
  const logout = () => {
    setStorage('session', null);
    setStorage('user', null);
    window.location.href = '/';
  }

  return (
    <Button onClick={() => logout()}>{_t("Log out")}</Button>
  );
}

export default Logout;
