import { Twitter } from "@mui/icons-material";
import { Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { _t } from "./utils/i18n";

const Teaser = () => {
  return (
    <Box className="Teaser" sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "100%", minHeight: "100vh", marginTop: { sx: 0, md: "-32px" } }}>
      <Box className="Hero" display="flex" flexDirection="column" sx={{ width: "100%" }}>
        <Box className="Illustration" sx={{
          position: "relative",
          height: { xs: '325px', md: '400px' },
          width: { xs: '100%', md: '100%' },
          overflow: "hidden",
          backgroundImage: "url('/hero.jpeg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          opacity: 1,
        }}>
          <Box
            sx={{
              position: "absolute",
              top: '-50px',
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1,
              backgroundColor: "rgba(0, 0, 0, 0.9)",
              opacity: 1,
              maskImage: "linear-gradient(transparent,rgb(0,0,0));",
            }}
          />
          <Box
            className="HeroText"
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 20,
              right: 20,
              zIndex: 2,
            }}
          >
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
              <Typography sx={{
                fontSize: { xs: "26px", md: "32px" },
                fontWeight: 700,
                color: "white",
              }} variant="h1">
                {_t("Welcome to Jolkia - the ultimate challenge app for cyclists and runners!")}
              </Typography>
              <Box mt={2}>
                <Typography sx={{
                  fontSize: { xs: "20px", md: "26px" },
                  color: "white",
                }} variant="h2">
                  {_t("Create and join unique challenges around cycling and running, challenge yourself and compete against others, while having fun and staying motivated.")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="Content">

        <Box mt={4}>
          <Typography variant="h3" sx={{ fontSize: "24px", fontWeight: "900" }}>{_t("How it works").toUpperCase()}</Typography>
        </Box>

        <Box className="Section" sx={{ display: "flex", flexDirection: "column", padding: { xs: "0 20px", md: "0 50px"} }}>
          <Box className="SectionContent" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", mt: 0 }}>
            <Box
              className="SectionContentDetails"
              display="flex"
              alignItems="center"
              sx={{ flexDirection: { xs: "column", md: "row" }}}
            >
              <Box
                component="img"
                loading='lazy'
                src="/illustration_create_challenge.png"
                sx={{
                  maxWidth: { xs: '50vw', md: '200px' },
                  maxHeight: { xs: '60vw', md: 'auto' },
                  mt: { xs: 2, md: 0 },
                  mr: { xs: 0, md: 4 },
                  borderRadius: "8px",
                }}
              />
              <Box mt={4}>
                <Typography sx={{ textAlign: "left", mb: 4 }}
                  dangerouslySetInnerHTML= {{ __html: _t("<strong>Creating a challenge has never been so easy!</strong><br /><br/>Simply choose the challenge type (e.g. kilometers, elevation gain, elapsed time, or timed segments \"Grand Prix\") and set the parameters (e.g. title, description, time span, segments).<br/>You can make challenges public or private, invite friends or join existing challenges.<br/>Jolkia tracks your progress and shows you how you rank against others in the challenge.")}}
                />

                <Typography sx={{ textAlign: "left", mb: 4 }}>{_t("Joining and existing challenge is even more easier! Find appropriate public challenges, click on Join, and voila!")}</Typography>

                <Button component={Link} to={"/challenges"} variant="contained">{_t("See all public challenges")}</Button>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box mt={4}>
          <Typography variant="h3" sx={{ fontSize: "24px", fontWeight: "900" }}>{_t("The Grand Prix Challenge").toUpperCase()}</Typography>
        </Box>

        <Box className="Section" sx={{ display: "flex", flexDirection: "column", padding: { xs: "0 20px", md: "0 50px"} }}>
          <Box className="SectionContent" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", mt: 4 }}>
            <Box
              className="SectionContentDetails"
              display="flex"
              alignItems="center"
              sx={{ flexDirection: { xs: "column", md: "row" }}}
            >
              <Box
                component="img"
                loading='lazy'
                src="/illustration_map_gp.png"
                sx={{
                  order: { xs: 1, md: 2 },
                  maxWidth: { xs: '50vw', md: '200px' },
                  maxHeight: { xs: '60vw', md: 'auto' },
                  mt: { xs: 2, md: 0 },
                  mr: { xs: 0, md: 4 },
                  borderRadius: "8px",
                }}
              />
              <Box mt={4} sx={{
                order: { xs: 2, md: 1 },
              }}>
                <Typography
                  sx={{ textAlign: "left", mb: 4 }}
                  dangerouslySetInnerHTML={{ __html: _t("<strong>An unique way to challenge</strong><br/><br/>The Grand Prix Challenge is our signature challenge type, inspired by formula one Grand Prix races.<br/>In this challenge, the challenge creator selects 10-15 Strava segments not far away from each other, and lets riders/runners complete them to earn points, during a specific time span.<br/>Finishing first on a segment grants you 25 points, while finishing second only 18 points, 3rd 15 points, and so on..<br/>The rider/runner with the most points at the end is declared the winner! The Grand Prix Challenge is unique, fun and addictive - try it now!") }}
                />
                <Button component={Link} to={"/challenges?type=gp"} variant="contained">{_t("See Grand Prix challenges")}</Button>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box mt={4}>
          <Typography variant="h3" sx={{ fontSize: "24px", fontWeight: "900" }}>{_t("What you could gain").toUpperCase()}</Typography>
        </Box>

        <Box className="Section" sx={{ display: "flex", flexDirection: "column", padding: { xs: "0 20px", md: "0 50px"} }}>
          <Box className="SectionContent" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", mt: 4 }}>
            <Box
              className="SectionContentDetails"
              display="flex"
              alignItems="center"
              sx={{ flexDirection: { xs: "column", md: "row" }}}
            >
              <Box
                component="img"
                loading='lazy'
                src="/illustration_my_activities.png"
                sx={{
                  maxWidth: { xs: '50vw', md: '200px' },
                  maxHeight: { xs: '60vw', md: 'auto' },
                  mt: { xs: 2, md: 0 },
                  mr: { xs: 0, md: 4 },
                  borderRadius: "8px",
                }}
              />
              <Box mt={4} sx={{
                order: { xs: 2, md: 1 },
              }}>
                <Typography
                  sx={{ textAlign: "left", mb: 4 }}
                  dangerouslySetInnerHTML={{ __html: _t("<strong>For athletes, clubs and regions</strong><br/><br/>Jolkia offers a range of benefits to users, including motivation to push yourself and reach new goals, opportunities to connect with like-minded cyclists and runners, recognition and rewards for your achievements, and fun and engaging challenges that keep you coming back for more.<br/>For clubs or associations, this is a great way to motivate your members and organize fun events.<br/>Regions can encourage athletes to visit their areas by creating Grand Prix challenges that cover the most beautiful places they have to offer.") }}
                />
                <Button component={Link} to={"/connect"} variant="contained">{_t("Get started now!")}</Button>
              </Box>
            </Box>
          </Box>
        </Box>

      </Box>
      <Box mt={4} className="footer">
        <Box mt={4} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Box sx={{ mr: 2 }}>
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Box sx={{ mr: 2 }}>
                  <a href="https://twitter.com/jolkia_" target="_blank" rel="noreferrer">
                  <Twitter sx={{ fontSize: 32 }} />
                  </a>
                </Box>
              </Box>
            </Box>
            <Box sx={{ mr: 2 }}>
              <Link to="/about">
                <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: "900" }}>{_t("About")}</Typography>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box my={2} />
    </Box>
  );
};

export default Teaser;
