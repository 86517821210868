import { useEffect, useState } from 'react';
import { getStorage, setStorage } from './utils';
import { _t } from './utils/i18n';

import { API_URL } from './config';
import { Link } from 'react-router-dom';

import xhr from './xhr.js';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Teaser from './Teaser';

const Home = () => {
  const [user, setUser] = useState(null);
  const [error, setError ] = useState(null);
  const session = getStorage('session');

  useEffect(() => {
    if (!session) return;

    const fetchData = async () => {
      const data = await xhr(API_URL.clone().segment('check_session').toString(), {
        method: 'POST',
      });

      if (data.error) {
        setError(data.error);
        return;
      }

      setUser(data.user);
      setStorage('user', data.user);
    };

    fetchData();
  }, [session]);

  if ((!user && !session) || error) {
    return <Teaser />;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "80vh" }}>

      {user && (
        <Box display="flex" flexDirection="column" sx={{ m: 4, width: "80%", maxWidth: "400px" }}>
          <Typography variant="h5">{_t("Hello")} <strong>{user.first_name}</strong> !</Typography>
          <Box my={2}>
            <Button fullWidth sx={{ m: 2 }} variant="contained" component={Link} to="/my/challenges">{_t("My challenges")}</Button>
            <Button fullWidth sx={{ m: 2 }} variant="outlined" component={Link} to="/sync">{_t("My activities")}</Button>
          </Box>
        </Box>
      )}

      {!user && session && !error && (
        <Box>{_t("Connecting...")}</Box>
      )}

      <Box mt={10}>
        <img width="150px" src="api_logo_cptblWith_strava_horiz_light.svg" alt="Strava compatible" />
      </Box>
    </Box>
  );
}

export default Home;
