import { MoreHoriz } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { API_URL } from "../config";
import { alert } from "../utils/emitter";
import { _t } from "../utils/i18n";
import xhr from "../xhr";

const ChallengerOptions = ({ challenge, challenger, onDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirm, setConfirm] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(() => setConfirm(false), 200);
  };

  const handleDelete = async () => {
    const url = API_URL
      .clone()
      .segment('challenges')
      .segment(challenge.id)
      .segment('challengers')
      .segment(challenger.user_id)
      .toString();

      try {
        const data = await xhr(url, {
          method: 'DELETE',
        });

        if (data.error) {
          console.log('Erreur lors de la suppression du challenger', data.error);
          return;
        }

        alert(_t("Challenger successfully deleted. Page will refresh"), 'success', 5000);
        setTimeout(() => window.location.reload(), 300);

      } catch (e) {
        console.error('Erreur lors de la suppression du challenger', e);
        return;
      }

    onDelete();
  };

  return (
    <>
      <MoreHoriz onClick={handleClick} />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {confirm ? (
          <>
            <MenuItem onClick={handleDelete}>{_t("I confirm")}</MenuItem>
            <MenuItem onClick={handleClose}>{_t("Abort")}</MenuItem>
          </>
        ) : (
          <MenuItem onClick={() => setConfirm(true)}>{_t("Exclude")}</MenuItem>
        )}
      </Menu>
    </>
  );
};

export default ChallengerOptions;
