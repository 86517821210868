import { Button, Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { _t } from "../utils/i18n";
import { Link } from "react-router-dom";

const Empty = () => {
  const navigate = useNavigate();

  const onCreate = () => navigate('/challenges/create');

  return (
    <Box mt={5} display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ height: "80vh" }}>
      <Container maxWidth="md">
        <Box>
          <Typography variant="h6">{_t("You are not participating in any challenge!")}</Typography>
        </Box>
        <Box mt={5} display="flex" flexDirection="column">
          <Box mb={2} display="flex" alignItems="center" justifyContent="center" width="100%">
            <Button component={Link} fullWidth variant="contained" color="primary" to={`/challenges`}>{_t("Browse public challenges")}</Button>
          </Box>
          <Box mb={2} display="flex" alignItems="center" justifyContent="center" width="100%">
            <Button fullWidth variant="contained" color="primary" onClick={onCreate}>{_t("Create your first challenge!")}</Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Empty;
