import { Button, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';

import { MAPBOX_TOKEN } from '../config';
import { _t } from '../utils/i18n';

// currently used through CDN since react-script try to bundle it each time
// maybe we can use a custom webpack config later and stop react-script to avoid this

// import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// import 'mapbox-gl/dist/mapbox-gl.css'; // eslint-disable-line import/no-webpack-loader-syntax

const invertCoords = (arr) => {
  const res = [];

  if (typeof arr[0] !== 'object')
    return [arr[1], arr[0]];

  for (let i = 0; i < arr.length; i++)
    res.push([arr[i][1], arr[i][0]]);

  return res;
};

const Map = ({ segments }) => {
  const mapEl = useRef(null);
  const mapRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [expandedMap, setExpandedMap] = useState(false);

  useEffect(() => {
    if (!mapRef.current) return;
    mapRef.current.resize();
  }, [expandedMap]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000);
  }, []);

  useEffect(() => {
    if (loading) return;
    if (!segments.length) return;

    window.mapboxgl.accessToken = MAPBOX_TOKEN;

    const map = new window.mapboxgl.Map({
      container: 'map', // container ID
      style: 'mapbox://styles/mapbox/outdoors-v12', // style URL
      center: invertCoords(segments[0].data.start_latlng),
      zoom: 4, // starting zoom
    });

    mapRef.current = map;

    map.on('load', () => {
      const routesData = {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: segments.map((segment) => ({
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: invertCoords(segment.data.coordinates),
            },
          })),
        },
      };

      const markersData = {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: segments.map((segment) => ({
            type: 'Feature',
            properties: {
              title: segment.data.name,
            },
            geometry: {
              type: 'Point',
              coordinates: invertCoords(segment.data.start_latlng),
            },
          })),
        },
      };

      map.addSource('routes', routesData);
      map.addLayer({
        'id': 'routes',
        'type': 'line',
        'source': 'routes',
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        'paint': {
          'line-color': '#D25C5D',
          'line-width': 5
        }
      });

      map.addSource('markers', markersData);
      map.addLayer({
        'id': 'markers',
        'type': 'symbol',
        'source': 'markers',
        'layout': {
          'text-field': '{title}',
          'icon-image': 'marker-15',
          'icon-allow-overlap': true,
        },
        paint: {
          'text-color': '#000',
        },
      });

      map.zoomTo(9, {
        duration: 2000,
      });

      map.addControl(new window.mapboxgl.NavigationControl());

    });

  }, [loading]);

  if (loading)
    return <Box sx={{ width: "100%", height: "150px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
      <CircularProgress />
      <Box mt={2}>{_t("Loading segments map...")}</Box>
    </Box>;

  return (
    <>
      <Box sx={{ position: "absolute", top: "10px", left: "10px", zIndex: 1 }}>
        <Button onClick={() => setExpandedMap(!expandedMap)}>{expandedMap ? _t("Minimize") : _t("Maximize")}</Button>
      </Box>
      <Box sx={{
        width: '100%',
        height: expandedMap ? {
          xs: "400px",
          md: "600px",
        } : {
          xs: "200px",
          md: "400px",
        },
        position: 'relative',
        overflow: 'hidden',
      }} id="map" ref={mapEl} />
    </>
  );
};

export default Map;
