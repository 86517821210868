import { Avatar, Box, Button, Card, Input, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { API_URL } from './config';
import SelectMultiple from './Dumbs/SelectMultiple';
import { getStorage, setStorage } from './utils';
import { alert } from './utils/emitter';
import { _t } from './utils/i18n';
import xhr from './xhr';

const AVAILABLE_SPORT_TYPES = [
  "Run",
  "TrailRun",
  "Ride",
  "VirtualRide",
];

const Profile = () => {
  const user = getStorage('user');
  const [sport_types, setSportTypes] = useState(user.sport_types || []);
  const [email, setEmail] = useState(user.email || '');

  const onSave = async () => {
    const data = await xhr(API_URL.clone().segment('users').segment('profile').toString(), {
      method: 'POST',
      body: JSON.stringify({
        sport_types,
        email,
      }),
    });

    if (data.error && data.code) {
      alert(data.error, "error", 5000);
      return;
    }

    setStorage('user', data.user);
    alert(_t("Profile updated!"), "success", 5000);
  };

  let gender;

  switch (user.gender) {
    case "M": gender = _t("Male"); break;
    case "F": gender = _t("Female"); break;
    default:
      gender = _t("Not specified");
  }

  return (
    <Box>
      <Typography variant="h2">{_t("Profile")}</Typography>

      <Box m={4}>
        <Card>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={2}>
            <Typography variant="h6">{_t("Imported from Strava")}</Typography>
            <Box mb={2} />
            <Avatar sx={{ mr: 2 }} alt={`${user.first_name} ${user.last_name}`} src={user.avatar} />
            <Typography>{user.first_name} {user.last_name}</Typography>
            <Typography>{_t("Gender:")} {gender}</Typography>
            <Typography>{_t("Location:")} {user.city || _t("Not specified")}</Typography>
            <Typography>{_t("Registered on:")} {dayjs(user.created_on).format("LL")}</Typography>

            <Button href="https://www.strava.com/settings/profile" target="_blank" sx={{ mt: 2 }}>{_t("Modify on Strava")}</Button>
          </Box>
        </Card>

        <Box m={4} />

        <Card>
          <Box p={2}>
            <Typography variant="h6">{_t("Jolkia settings")}</Typography>
            <Box m={2} display="flex" justifyContent="center" alignItems="center">
              <Input fullWidth sx={{ mr: 2 }} placeholder={_t("Email")} defaultValue={email} onChange={e => setEmail(e.target.value)} />
            </Box>
            <SelectMultiple
              label={_t("Prefered sports types")}
              initialValue={sport_types}
              options={AVAILABLE_SPORT_TYPES}
              onChange={setSportTypes}
            />

            <Button onClick={onSave} variant="contained" sx={{ mt: 2 }}>{_t("Save")}</Button>
          </Box>
        </Card>
      </Box>
    </Box>
  );
}

export default Profile;
