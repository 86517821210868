import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import './App.css';
import Home from './Home';
import Connect from './Connect';
import Logout from './Logout';
import All from './Challenges/All';
import Challenges from './Challenges/Challenges';
import Challenge from './Challenges/Challenge';
import Layout from './Layout';
import Sync from './Sync';
import About from './About';
import Create from './Challenges/Create';
import Search from './Challenges/Search';
import ShortCode from './Challenges/ShortCode';
import ManageSegments from './Challenges/ManageSegments';
import Admin from './Admin';
import Profile from './Profile';

import { Route, Routes } from "react-router-dom";
import { BrowserRouter as Router } from 'react-router-dom';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { Box, Typography } from '@mui/material';

import i18n, { getLocale } from './utils/i18n';
import frenchDictionnary from "./i18n/fr.json";

import { useEffect, useState } from 'react';

import dayjs from "dayjs";
import Clubs from './Clubs/Clubs';
import Add from './Clubs/Add';

import '@tremor/react/dist/esm/tremor.css';
import { getStorage } from './utils';

import sentry from './utils/sentry';

require("dayjs/locale/en");
require("dayjs/locale/fr");

const theme = createTheme({
  // palette: {
  //   primary: {
  //     main: '#ff9933',
  //   },
  //   success: {
  //     main: '#c8e378',
  //   },
  //   error: {
  //     main: '#c2352a',
  //   },
  // }
  palette: {
    primary: {
      main: '#D25C5D',
    },
  }
});

function withLayout(Component, props) {
  return (
    <ThemeProvider theme={theme}>
      <Layout {...props}>
        <Component {...props} />
      </Layout>
    </ThemeProvider>
  );
}

const NotFound = () => {
  return (
    <Box>
      <Typography variant="h1" component="h1">404</Typography>
      <Box>Page non trouvée.</Box>
    </Box>
  );
}

function App() {
  const [locale, setLocale] = useState(null);
  const user = getStorage("user");

  useEffect(() => {
    const language = getLocale();

    if (user)
      sentry.setUser(user);

    switch (language) {
      case 'fr':
        dayjs.locale('fr');
        i18n.load(frenchDictionnary);
        setLocale('fr');
        break;
      default:
        dayjs.locale('en');
        setLocale('en');
        break;
    }
  }, []);

  if (!locale) return null;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box className="App">
        <Router>
          <Routes>
            <Route exact path="/" element={withLayout(Home)} />
            <Route exact path="/connect" element={withLayout(Connect)} />
            <Route exact path="/s/:code" element={withLayout(ShortCode)} />
            <Route exact path='/clubs' element={withLayout(Clubs)} />
            <Route exact path='/clubs/add' element={withLayout(Add)} />
            <Route exact path="/challenges/create" element={withLayout(Create, { mustBeLogged: true })} />
            <Route exact path="/challenges/search" element={withLayout(Search)} />
            <Route exact path="/challenges/:id/edit" element={withLayout(Create, { mustBeLogged: true })} />
            <Route exact path="/challenges/:id/segments/edit" element={withLayout(ManageSegments)} />
            <Route exact path="/challenges" element={withLayout(All)} />
            <Route exact path="/my/challenges" element={withLayout(Challenges, { mustBeLogged: true })} />
            <Route exact path="/challenge/:id" element={withLayout(Challenge)} />
            <Route exact path="/sync" element={withLayout(Sync, { mustBeLogged: true })} />
            <Route exact path="/logout" element={withLayout(Logout, { mustBeLogged: true })} />
            <Route exact path="/about" element={withLayout(About)} />
            <Route exact path="/admin" element={withLayout(Admin)} />
            <Route exact path="/profile" element={withLayout(Profile, { mustBeLogged: true })} />
            <Route path="*" element={withLayout(NotFound)} />
          </Routes>
        </Router>
      </Box>
    </LocalizationProvider>
  );
}

export default App;
