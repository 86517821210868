import { Button, Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { getStorage, setStorage } from "../utils";
import { _t } from "../utils/i18n";

const Cookies = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const cookies = getStorage('cookies');
    if (cookies) return;

    setShow(true);
  }, []);

  if (!show) return null;

  return (
    <Box display="flex" justifyContent="center" alignItems="center" className="cookies" sx={{
      position: "fixed",
      padding: "5px 10px",
      left: "5px",
      bottom: "10px",
      borderRadius: "6px",
      background: "rgba(0, 0, 0, .9)",
      maxWidth: "calc(100vw - 30px)",
      zIndex: 1002,
    }}>
      <Typography sx={{
        color: "white",
        fontSize: "12px",
      }}>
       {_t("This website uses cookies to ensure you get the best experience on our website.")}
      </Typography>
      <Button
        onClick={() => {
          setStorage('cookies', true);
          setShow(false);
        }}
        sx={{
          marginRight: 0,
          paddingRight: 0,
          minWidth: "auto",
          fontSize: "12px",
        }}
      >
        {_t("ok").toUpperCase()}
      </Button>
    </Box>
  );
}

export default Cookies;
