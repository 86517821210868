import { useState, useEffect } from 'react';
import { getStorage } from './utils';
import dayjs from 'dayjs';
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { API_URL } from './config';
import xhr from './xhr';
import { _n, _t } from './utils/i18n';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import HikingIcon from '@mui/icons-material/Hiking';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';

import { Card, CardContent } from '@mui/material';
import { alert } from './utils/emitter';
import {
  Card as TremorCard,
  Metric,
  Text,
  AreaChart,
  Flex,
  ColGrid
} from "@tremor/react";

dayjs.locale('fr');
dayjs.extend(duration);
dayjs.extend(relativeTime);

const toKm = (distance) => (distance / 1000).toFixed(2);
const toHumanTime = (secs) => {
  const duration = dayjs.duration(secs, 'seconds');

  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (days > 0)
    return `${days}d ${hours}h`;

  if (hours === 0)
    return `${minutes}min ${seconds}s`;

  return `${hours}h ${minutes}min`;
}

const Cards = (stats) => {
  const data = stats.stats;

  const categories = [
    {
      title: _t('Distance'),
      key: 'distance',
      metric: `${toKm(data[data.length - 1].distance)} km`,
    },
    {
      title: _t('Duration'),
      key: 'moving_time',
      metric: toHumanTime(data[data.length - 1].moving_time),
    },
    {
      title: _t('Elevation'),
      key: 'elevation_gain',
      metric: `${data[data.length - 1].elevation_gain} m`,
    },
  ];

  return (
    <ColGrid numColsSm={ 2 } numColsLg={ 3 } gapX="gap-x-6" gapY="gap-y-6">
        {categories.map((item) => (
            <Card key={item.title}>
                <Flex alignItems="items-start">
                    <Text>
                      <Box ml={2} mt={1}>{item.title}</Box>
                    </Text>
                    {/* <BadgeDelta deltaType={ item.deltaType } text={ item.delta } /> */}
                </Flex>
                <Flex
                    justifyContent="justify-start"
                    alignItems="items-baseline"
                    spaceX="space-x-3"
                    truncate={true}
                >
                    <Metric>
                      <Box ml={2}>{item.metric}</Box>
                    </Metric>
                    {/* <Text>
                        from
                        { ' ' }
                        { item.metricPrev }
                    </Text> */}
                </Flex>
                <Box sx={{ display: { xs: 'none', md: 'block' }}}>
                  <AreaChart
                    marginTop="mt-4"
                    data={data}
                    dataKey="Month"
                    valueFormatter={(number) => number}
                    categories={[item.key]}
                    colors={['blue']}
                    showXAxis={true}
                    showGridLines={false}
                    startEndOnly={true}
                    showYAxis={false}
                    showLegend={false}
                    height="h-40"
                  />
                </Box>
            </Card>
        ))}
    </ColGrid>
  );
}

const computeStats = (activities) => {
  const data = [];

  let moving_time = 0;
  let distance = 0;
  let elevation_gain = 0;

  let currentDate = null;

  for (let i = 0; i < activities.length; i++) {
    const activity = activities[i];

    while (
      currentDate &&
      currentDate.isBefore(dayjs(activity.created_on), 'day')
    ) {
      data.push({
        date: currentDate.format('YYYY-MM-DD'),
        distance,
        moving_time,
        elevation_gain,
      });
      currentDate = currentDate.add(1, 'day');
    }

    distance += activity.distance;
    moving_time += activity.moving_time;
    elevation_gain += activity.elevation_gain;

    data.push({
      date: dayjs(activity.created_on).format('YYYY-MM-DD'),
      distance,
      moving_time,
      elevation_gain,
    });

    currentDate = dayjs(activity.created_on).add(1, 'day');
  };

  return data;
};

const Sync = () => {
  const [loading, setLoading] = useState(true);
  const [isSyncing, setIsSyncing] = useState(false);
  const [user, setUser] = useState(getStorage('user'));
  const [lastActivities, setLastActivities] = useState([]);
  const [stats, setStats] = useState([]);

  const synchronize = async () => {
    setIsSyncing(true);

    try {
      const data = await xhr(API_URL.clone().segment('sync').toString(), { method: 'POST' });

      setUser(data.user);
      setLastActivities([...data.new_activities, ...lastActivities]);

      if (data.new_activities.length === 0) {
        alert(_t("No new activities found"), 'info', 5000);
      } else {
        alert(_n("{count} new activity synchronized !", "{count} new activities synchronized !", data.new_activities.length, { count: data.new_activities.length }), 'success', 5000);
      }

    } catch (e) {
      console.error(e);
      alert(_t("An error occured while synchronizing your activities"), 'error', 5000);
    }

    setIsSyncing(false);
  };

  useEffect(() => {
    const fetchActivities = async () => {
      const data = await xhr(API_URL.clone().segment('activities').toString());
      setLastActivities(data.latest);
      setUser(data.user);
      setLoading(false);
    };

    const fetchStats = async () => {
      const data = await xhr(API_URL.clone().segment('user').segment('stats').toString());
      setStats(computeStats(data?.activities || []));
    };

    fetchActivities();
    fetchStats();
  }, []);

  // console.log('stats', stats)

  return (
    <Box>
      <Typography variant="h4">{_t("My activities")}</Typography>

      {loading && (
        <Box>{_t("Loading...")}</Box>
      )}

      {isSyncing && (
        <Box>{_t("Synchronizing...")}</Box>
      )}

      {!loading && lastActivities.length === 0 && (
        <Box>
          <p>{_t("You don't have any activity synchronized yet")}</p>
          <Button onClick={synchronize}>{_t("Synchronize")}</Button>
        </Box>
      )}

      {stats.length > 0 && (
        <Box p={2}>
          <TremorCard>
            <Typography variant="h6">{_t("My year so far (all sports)")}</Typography>
            <Box mt={2}>
              <Cards stats={stats} />
            </Box>
          </TremorCard>
        </Box>
      )}

      {lastActivities.length > 0 && (
        <Box sx={{ mx: 2 }}>
          <Box>
            <Box mb={2} display="flex" alignItems="center" justifyContent="center">
              <Typography sx={{ fontSize: "12px" }}>{_t("Last sync:")} <strong>{dayjs(user?.last_sync).format('DD/MM/YYYY HH:mm')}</strong></Typography>
              <Button sx={{ marginLeft: 2, height: "14px", width: "14px", minWidth: "25px", minHeight: "25px" }}size="small" variant="outlined" onClick={synchronize}><ReplayOutlinedIcon sx={{ height: "14px", width: "14px" }} /></Button>
            </Box>
          </Box>

          <Box>
            {lastActivities.map((activity, index) => (
              <Box key={index} mb={2}>
                <Card sx={{ minWidth: '300px' }}>
                  <CardContent sx={{ display: "flex" }}>

                    <Box display="flex" sx={{ width: "35px" }} flexDirection="column" justifyContent="center" alignItems="center">
                      {activity.sport_type === 'Run' && <DirectionsRunIcon />}
                      {activity.sport_type === 'TrailRun' && <DirectionsRunIcon />}
                      {activity.sport_type === 'Ride' && <DirectionsBikeIcon />}
                      {activity.sport_type === 'VirtualRide' && <DirectionsBikeIcon />}
                      {activity.sport_type === 'Hike' && <HikingIcon />}
                      <Typography sx={{ fontSize: 10 }}>{dayjs(activity.created_on).fromNow()}</Typography>
                    </Box>

                    <Box p={1} display="flex" flex="1 auto" justifyContent="center" alignItems="center">
                      <Box>{activity.name}</Box>
                    </Box>

                    <Box display="flex" sx={{ width: "90px" }} flexDirection="column" justifyContent="center" alignItems="center">
                      <Typography sx={{ fontSize: 12 }}>{toKm(activity.distance)} km</Typography>
                      <Typography sx={{ fontSize: 12 }}>{toHumanTime(activity.moving_time)}</Typography>
                      <Typography sx={{ fontSize: 12 }}>{activity.total_elevation_gain}m D+</Typography>
                    </Box>

                  </CardContent>
                </Card>
              </Box>
            ))}
          </Box>
        </Box>
      )}

    </Box>
  );
}

export default Sync;
