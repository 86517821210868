import { useEffect, useState } from 'react';
import { getStorage, setStorage } from './utils';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';

import {
  API_URL,
  STRAVA_CLIENT_ID,
  STRAVA_AUTH_URL,
  STRAVA_REDIRECT_URL,
} from './config';
import { Box } from '@mui/system';
import { _t } from './utils/i18n';
import { CircularProgress } from '@mui/material';
import xhr from './xhr';

let once = false;

const oauthUrl = STRAVA_AUTH_URL
  .addSearch('client_id', STRAVA_CLIENT_ID)
  .addSearch('response_type', 'code')
  .addSearch('redirect_uri', STRAVA_REDIRECT_URL)
  .addSearch('approval_prompt', 'auto')
  .addSearch('scope', 'activity:read_all')
  .toString();

const Connect = () => {
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);
  const [error, setError ] = useState(null);

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const scope = searchParams.get('scope');
  const navigate = useNavigate();

  const userSession = getStorage('session');
  const storedUser = getStorage('user');

  useEffect(() => {
    if (!code || once) return;

    once = true;
    const fetchData = async () => {
      const response = await fetch(API_URL.clone().segment('check_code').toString(), {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ code, scope }),
      });
      const data = await response.json();

      if (data.error) {
        setError(data.error);
        return;
      }

      setUser(data.user);
      setSession(data.session);

      setStorage('user', data.user);
      setStorage('session', data.session);
    };

    fetchData();
  }, [code]);

  useEffect(() => {
    if (!session) return;

    // if first connection, sync activities
    // do not wait for the response
    if (!user.last_sync)
      xhr(API_URL.clone().segment('sync').toString(), { method: 'POST' });

    setTimeout(() => navigate('/'), 1500);
  }, [session]);

  if (!code && !userSession) {
    return (
      <Box p={4} display="flex" justifyContent="center" alignItems="center" flexDirection="column" sx={{ height: '60vh' }}>
        <Box my={2}>{_t("Connect in one click with my Strava account")}</Box>
        <a href={oauthUrl}>
          <img src="btn_strava_connectwith_orange.svg" />
        </a>
        <Box mt={2} sx={{ fontSize: "10px" }}>
          {_t("Jolkia will never know your Strava password nor email address. In addition, only read rights are required, no modification can be made to your account or your activities.")}
        </Box>
      </Box>
    );
  }

  if (!code && userSession && storedUser) {
    return (
      <Box>
        <h1>{_t("Already logged in")}</h1>
        <Box>{_t("You're already logged in as ")} {storedUser.first_name}</Box>
        <Link to={"/"}>{_t("Back home")}</Link>&nbsp;•&nbsp;<Link to={"/logout"}>{_t("Log out")}</Link>
      </Box>
    );
  }

  if (!code || error) {
    return (
      <Box>
        <h1>{_t("Connecting")}</h1>
        <Box>
          {_t("An error occurred, no connection code or wrong connection code found.")}
        </Box>
        <Box>{error}</Box>
      </Box>
    );
  }

  if (!session) {
    return (
      <Box>
        <h1>{_t("Connecting")}</h1>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <h1>{_t("Welcome {name}!", { name: user.first_name })}</h1>
      <div>{_t("Redirecting...")}</div>
    </Box>
  );
}

export default Connect;
