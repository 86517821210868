import { getStorage } from "./utils";
import Box from '@mui/material/Box';
import ResponsiveAppBar from "./components/AppBar";
import FooterOverlay from "./components/FooterOverlay";
import Alert from "./components/Alert";
import MustLogin from "./components/MustLogin";
import Cookies from "./Dumbs/Cookies";
import Email from "./Dumbs/Email";
import Feedback from "./components/Feedback";

const Layout = ({ children, mustBeLogged }) => {
  const session = getStorage('session');

  return (
    <Box className="Layout" sx={{ minHeight: "100vh", background: "#f5f5f5" }}>
      <ResponsiveAppBar />
      <Alert />
      <Feedback />

      {mustBeLogged && !session && (
        <MustLogin />
      )}

      <Box className="Content" display="flex" flexDirection="column" sx={{ pt: { xs: '68px', md: '100px' }, minHeight: '90vh' }}>
        {children}

        {!session && (
          <FooterOverlay />
        )}
      </Box>

      <Cookies />
      <Email />
    </Box>
  );
}

export default Layout;
