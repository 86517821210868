import { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import { getStorage, removeStorage, setStorage } from '../utils';
import { getLocale, setLocale, _t } from '../utils/i18n';

function ResponsiveAppBar() {
  const navigate = useNavigate();
  const user = getStorage('user');
  const session = getStorage('session');

  const pages = [];

  const settings = [
    {
      title: _t("Profile"),
      link: '/profile',
    },
    {
      title: _t("Logout"),
      link: '/logout',
    },
  ];

  const root_user = getStorage('root_user');
  const root_session = getStorage('root_session');

  if (user?.is_root)
    settings.push({ title: 'Administration', link: '/admin' });

  pages.push({ title: _t("Home"), link: '/' });
  pages.push({ title: _t("Clubs"), link: '/clubs' });

  if (session) {
    pages.push({ title: _t("My Challenges"), link: '/my/challenges' });
    pages.push({ title: _t("My activities"), link: '/sync' });
    pages.push({ title: _t("Create a challenge"), link: '/challenges/create' });
  } else {
    pages.push({ title: _t("Challenges"), link: '/challenges' });
    pages.push({ title: _t("About"), link: '/about' });
  }

  pages.push({ title: _t("🇫🇷"), link: '#', onClick: () => {
    setLocale(getLocale() === 'fr' ? 'en' : 'fr');
    window.location.reload();
  }});

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (callback) => {
    setAnchorElNav(null);
    typeof callback === 'function' && callback();
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="fixed">
      <Container maxWidth="xl" sx={{ color: "white" }}>
        <Toolbar disableGutters>

          <Box display="flex" onClick={() => navigate('/')}>
            <Box
              component="img"
              loading='lazy'
              className="JolikaLogo"
              sx={{ height: "32px", display: { xs: 'none', md: 'flex' }, mr: 1 }}
              src="/jolika-logo-white.png"
            />

            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              Jolkia
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map(({ title, link, onClick }) => (
                <MenuItem key={link} onClick={onClick}>
                  <Link to={link} style={{ textDecoration: 'none' }} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{title}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box display="flex" sx={{ flex: "1 auto", justifyContent: "center", alignItems: "center" }} onClick={() => navigate('/')}>

            <Box display="flex" sx={{ justifyContent: "center", alignItems: "center" }}>
              <Box
                component="img"
                loading='lazy'
                className="JolikaLogo"
                sx={{  height: "32px", display: { xs: 'flex', md: 'none' }, mr: 1 }}
                src="/jolika-logo-white.png"
              />
              <Typography
                variant="h5"
                noWrap
                component="a"
                href=""
                sx={{
                  mr: 2,
                  display: { xs: 'flex', md: 'none' },
                  flexGrow: 1,
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                Jolkia
              </Typography>
            </Box>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map(({ title, link, onClick }) => (
              <Link to={link} key={link} style={{ textDecoration: 'none' }} onClick={onClick}>
                <Button
                  key={link}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {title}
                </Button>
              </Link>
            ))}
          </Box>

          {session && (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title={_t("User preferences")}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={`${user.first_name} ${user.last_name}`} src={user.avatar} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map(({ title, link }) => (
                  <MenuItem key={link} onClick={handleCloseUserMenu}>
                    <Link style={{ textDecoration: 'none' }} to={link}>
                      <Typography textAlign="center">{title}</Typography>
                    </Link>
                  </MenuItem>
                ))}

                {root_user && root_session && (
                  <MenuItem onClick={() => {
                    setStorage('session', root_session);
                    setStorage('user', root_user);
                    removeStorage('root_session');
                    removeStorage('root_user');
                    window.location.reload();
                  }}>
                    <Typography textAlign="center">Restaurer la session</Typography>
                  </MenuItem>
                )}
              </Menu>
            </Box>
          )}

          {!session && (
            <Box sx={{ flexGrow: 0 }}>
              <Link style={{ textDecoration: 'none' }} to="/connect">
                <Button
                  variant="contained"
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                 {_t("Connect")}
                </Button>
              </Link>
            </Box>
          )}

        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
