import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import emitter from "../utils/emitter";
import BaseAlert from "@mui/material/Alert";
import Confetti from 'react-confetti';
import { Typography } from "@mui/material";

const INTERVAL_MS = 10;

const Alert = () => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('success');
  const [callback, setCallback] = useState(null)
  const [initialTimeout, setInitialTimeout] = useState(0);
  const [remaining, setRemaining] = useState(0);

  const onEvent = (event) => {
    setMessage(event.message);
    setType(event.type);
    setShow(true);
    setCallback({ done: event.action });

    if (event.timeout) {
      setInitialTimeout(event.timeout);
      setRemaining(event.timeout - INTERVAL_MS);

      const interval = setInterval(() => {
        setRemaining((remaining) => remaining - INTERVAL_MS);
      }, INTERVAL_MS);

      setTimeout(() => {
        setShow(false);
        clearInterval(interval);
      }, event.timeout);
    }
  };

  useEffect(() => {
    emitter.on("alert", onEvent);

    return () => {
      emitter.off("alert", onEvent);
    };
  }, []);

  if (!show) return null;

  let color = "green";

  switch (type) {
    case "success":
      color = "green";
      break;
    case "error":
      color = "red";
      break;
    case "warning":
      color = "orange";
      break;
    case "info":
    default:
      color = "blue";
      break;
  }

  const remainingWidth = Math.floor((remaining / initialTimeout) * 100);

  if (type === "congrats") {
    // display a confetti modal centered on the screen
    return (
      <>
        <Confetti />
        <Box sx={{
          position: 'fixed',
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 3001,
          width: "80vw",
          maxWidth: "600px",
          minHeight: "100px",
          color: "white",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          borderRadius: "15px",
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <Typography>
            {message}
          </Typography>
        </Box>
      </>
    )
  }

  return (
    <Box sx={{
      position: 'fixed',
      bottom: "5px",
      left: "5px",
      right: "5px",
      zIndex: 3000,
    }}>
      <BaseAlert
        severity={type}
        onClose={() => setShow(false)}
      >
        <Box onClick={() => callback?.done && callback.done() && setShow(false)}>
          {message}
        </Box>
      </BaseAlert>

      {initialTimeout > 0 && (
        <Box
          sx={{
            height: "3px",
            width: remainingWidth + "%",
            marginTop: "-3px",
            borderBottomLeftRadius: "15px",
            backgroundColor: color,
            opacity: 0.6,
          }}
        />
      )}
    </Box>
  );
};

export default Alert;
