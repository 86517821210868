import { Edit } from "@mui/icons-material";
import { Button, Card, CardActions, CardContent, CardMedia, TextareaAutosize, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { API_URL } from "../config";
import { getStorage } from "../utils";
import { alert } from "../utils/emitter";
import { _t } from "../utils/i18n";
import xhr from "../xhr";

const ClubCard = ({ club, actions = [] }) => {
  const user = getStorage('user');

  const [edit, setEdit] = useState(false);
  const [description, setDescription] = useState(club.description || '');

  const onSave = async () => {
    const data = await xhr(API_URL.clone().segment('clubs').segment(`${club.id}`).toString(), {
      method: 'POST',
      body: JSON.stringify({
        description,
      }),
    });

    if (!data.club) {
      alert(_t("An error occurred while editing the club"), 'error', 5000);
      return;
    }

    alert(_t("Club edited"), 'success', 5000);
    setDescription(data.club.description);
    setEdit(false);
  };

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        sx={{ height: 140 }}
        image={club.cover_photo || `https://picsum.photos/640/360?r=${Math.random()}`}
        title="green iguana"
      />
      <CardContent sx={{ position: 'relative' }}>
        <Box
          component="img"
          src={club.profile || `https://picsum.photos/640/360?r=${Math.random()}`}
          sx={{
            border: "2px solid white",
            borderRadius: "6px",
            marginTop: "-50px",
          }}
        />
        <Typography variant="h5" component="div">
          {club.name}
        </Typography>
        <Typography variant="body2" color="text.secondary" component="div">
          {user?.id === club.owner_id && !edit && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                zIndex: 1,
              }}
            >
              <Button onClick={() => setEdit(true)}><Edit /></Button>
            </Box>
          )}
          {!edit ? description : null}
          {edit && (
            <>
              <TextareaAutosize
                defaultValue={description}
                rowsMin={5}
                rowsMax={10}
                onChange={(e) => setDescription(e.target.value)}
                placeholder={_t("Description")}
                style={{ width: "100%" }}
              />
              <Button onClick={onSave}>{_t("Save")}</Button>
            </>
          )}
        </Typography>
      </CardContent>
      <CardActions>
        {actions.map(({ label, onClick }) => (
          <Button key={label} onClick={onClick} size="small">{label}</Button>
        ))}
        <Button>
          <a style={{ color: "inherit", textDecoration: "none" }} target="_blank" href={`https://www.strava.com/clubs/${club.url}`}>{_t("See on Strava")}</a>
        </Button>
      </CardActions>
    </Card>
  );
}

export default ClubCard;
