const humanizeDuration = (duration) => {
  if (duration === 0) return "--";

  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - (hours * 3600)) / 60);
  const seconds = duration - (hours * 3600) - (minutes * 60);

  let result = "";

  if (hours > 0) {
    result += `${hours}h`;
  }

  if (minutes > 0) {
    result += ` ${minutes}min`;
  }

  if (seconds > 0 && hours === 0) {
    result += ` ${seconds}s`;
  }

  return result;
}

export default humanizeDuration;
