
import { SENTRY_URL } from '../config';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const getSentry = () => {

  if (!SENTRY_URL)
    return {
      intialized: false,
      setUser: () => {},
      clearUser: () => {},
    };

  Sentry.init({
    dsn: SENTRY_URL,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });

  return {
    intialized: true,
    setUser: (user) => {
      Sentry.setUser({ id: user.id });
    },
    clearUser: () => {
      Sentry.setUser(null);
    },
  }
};

const sentry = getSentry();

export default sentry;
