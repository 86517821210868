import { useEffect, useState } from 'react';

import { API_URL } from '../config';
import xhr from '../xhr';
import ChallengeCard from './ChallengeCard';
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';

import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import Search from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import { getStorage } from '../utils';

import EmptyChallenges from './Empty';
import { useNavigate } from 'react-router-dom';
import { _t } from '../utils/i18n';

const Challenges = () => {
  const session = getStorage('session');
  const navigate = useNavigate();

  const [error, setError ] = useState(null);
  const [loading, setLoading] = useState(true);
  const [challenges, setChallenges] = useState([]);
  const [filters, setFilters] = useState({});

  const changeFilter = (filter) => {
    const newFilters = { ...filters, [filter]: !filters[filter] };

    if (!newFilters.ride && !newFilters.run) {
      setFilters({});
      return;
    }

    setFilters(newFilters);
  };

  useEffect(() => {
    if (!session) return;

    const fetchChallenges = async () => {
      const data = await xhr(API_URL.clone().segment('challenges').addSearch('mine', true).toString(), {});

      if (data.error) {
        setError(data.error);
        return;
      }

      setLoading(false);
      setChallenges(data.challenges);
    };

    setLoading(true);
    fetchChallenges();
  }, []);

  if (!loading && challenges.length === 0)
    return <EmptyChallenges />;

  const filteredActiveChallenges = challenges
    .filter((challenge) => {
      if (new Date(challenge.end_date) < new Date())
        return false;

      if (Object.keys(filters).length === 0) return true;

      if (challenge.sport_types.includes('Ride') && filters.ride)
        return true;

      if (challenge.sport_types.includes('Run') && filters.run)
        return true;

      return false;
    })
    .sort((a, b) => new Date(a.end_date) - new Date(b.end_date));

  const filteredEndedChallenges = challenges
    .filter((challenge) => {
      if (new Date(challenge.end_date) >= new Date())
        return false;

      if (Object.keys(filters).length === 0) return true;

      if (challenge.sport_types.includes('Ride') && filters.ride)
        return true;

      if (challenge.sport_types.includes('Run') && filters.run)
        return true;

      return false;
    })
    .sort((a, b) => new Date(a.end_date) - new Date(b.end_date));

  return (
    <Box>
      <Typography variant="h4">{_t("My Challenges")}</Typography>

      <Box display="flex" justifyContent="center" alignItems="center" sx={{ margin: "10px auto", width: "80vw", maxWidth: 600 }}>
        <Box sx={{ display: "flex", flex: "1 auto" }}>
          <Box onClick={() => changeFilter('ride')} sx={{ padding: "3px" }}>
            <Button sx={{ fontSize: "10px", padding: "3px" }} variant={filters.ride ? 'contained' : 'outlined'} size="small">
              <DirectionsBikeIcon sx={{ fontSize: "15px", height: "15px", marginRight: "3px" }} />
              {_t('Ride')}
              {filters.ride && <CancelIcon sx={{ fontSize: "15px", height: "15px", marginLeft: "3px" }} />}
            </Button>
          </Box>
          <Box onClick={() => changeFilter('run')} sx={{ padding: "3px" }}>
            <Button sx={{ fontSize: "10px", padding: "3px" }} variant={filters.run ? 'contained' : 'outlined'} size="small">
              <DirectionsRunIcon sx={{ fontSize: "15px", height: "15px", marginRight: "3px" }} />
              {_t('Run')}
              {filters.run && <CancelIcon sx={{ fontSize: "15px", height: "15px", marginLeft: "3px" }} />}
            </Button>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="center" >
          <Button sx={{ margin: 0, padding: 0, minWidth: 0 }} onClick={() => navigate('/challenges')}>{_t("Public challenges")}</Button>
        </Box>

      </Box>

      {loading && (
        <Box sx={{ display: 'flex', height: '50vh', justifyContent: "center", alignItems: "center" }}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <CircularProgress />
            {_t("Loading...")}
          </Box>
        </Box>
      )}

      {!loading && filteredActiveChallenges.length === 0 && filteredEndedChallenges.length === 0 && (
        <Box mt={5}><Typography variant="h6">{_t("There are no challenges that match your search criteria.")}</Typography></Box>
      )}

      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
        {filteredActiveChallenges.map((challenge, i) => <ChallengeCard key={i} {...challenge} />)}

        {filteredEndedChallenges.length > 0 && (
          <>
            <Divider sx={{ width: "80vw", maxWidth: 600, margin: "10px auto" }} />
            <Typography sx={{ marginTop: "5px", marginBottom: "15px" }} variant="h6">{_t("Ended challenges")}</Typography>
            {filteredEndedChallenges.map((challenge, i) => <ChallengeCard key={i} {...challenge} />)}
          </>
        )}
      </Box>

    </Box>
  );
}

export default Challenges;
